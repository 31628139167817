<template>
  <div class="freeze-dialog-box">
    <el-dialog v-if="isFreeze" title="冻结" :visible.sync="dialogFormVisible">
      <div class="freeze-info left-align">
        <div class="bold">
            <img src="@/assets/iconImg/warn.png" style="width: 14px;margin-right: 3px;" />
            <span>确定冻结该组织？</span>
        </div>
        <div style="color: #5A5A5A;">冻结组织后，该组织及下级组织均会被冻结，且会有以下影响，请慎重操作：</div>
        <div style="color: #5A5A5A;">1.组织管理员将无法登录组织账号；</div>
        <div style="color: #5A5A5A;">2.组织下的员工将会全部冻结，员工无法分配未使用的任务资源；</div>
        <div style="color: #5A5A5A;">3.组织的资金将会被冻结，无法使用；</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('freeze')">冻 结</el-button>
      </div>
    </el-dialog>
    <el-dialog v-else title="取消冻结" :visible.sync="dialogFormVisible">
      <div class="freeze-info left-align">
        <div class="bold">
            <img src="@/assets/iconImg/warn.png" style="width: 14px;vertical-align:middle;margin-right: 3px;" />
            <span>是否取消冻结？</span>
        </div>
        <div style="color: #5A5A5A;">取消冻结后，组织账号将会恢复正常</div>
        <div style="color: #5A5A5A;">员工仍处于已冻结状态，可前往“批量操作”—“批量取消冻结”恢复员工账号</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('cancel')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: "FreezeOrgDialog",
  props: {
    isShowPop: {
      type: Boolean,
      default: false
    },
    formObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isFreeze: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    dialogFormVisible: {
      get() {
        this.open = this.isShowPop
        return this.isShowPop
      },
      set(val) {
        this.open = val
        this.$emit('onClose',val)
      }
    }
  },
  data() {
    return {
      open: false,
      title: '冻结',
      loading: false,
    }
  },
  methods: {
    ...mapActions(['getOrgList','frozenOrgInfo','unFrozenOrgInfo']),
    submitForm(type) {
      if(this.loading) return
      this.loading = true
      if(type === 'freeze') {
        this.frozenOrgInfo({orgId: this.formObj.orgId}).then(res => {
          this.loading = false
          this.dialogFormVisible = false
          this.$message.success("组织冻结成功！")
          this.getOrgList()
        },rea => {
          this.loading = false
          this.$message({
            message:rea.message,
            type: 'error'
          })
        })
      } else {
        this.unFrozenOrgInfo({orgId: this.formObj.orgId}).then(res => {
          this.loading = false
          this.dialogFormVisible = false
          this.$message.success("取消冻结成功！")
          this.getOrgList()
        },rea => {
          this.loading = false
          this.$message({
            message:rea.message,
            type: 'error'
          })
        })
      }
    },
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.freeze-dialog-box {
    ::v-deep .el-dialog {
        width: fit-content;
        min-width: 460px;
    }
  ::v-deep .el-dialog .el-dialog__header {
    background-color: #ffffff;
    text-align: left;
    border-bottom: 1px solid #e1e1e1;
    .el-dialog__title {
      color: #0F0F0F;
      font-size: 14px;
    }
    .el-dialog__close {
      color: #909399 !important;
    }
  }
}
.freeze-info {
  width: fit-content;
  margin: auto;
  font-weight: bold;
  font-size: 14px;
  padding: 36px 0 30px;
  color: #0F0F0F;
  text-align: left;
  line-height: 22px;
}
.left-align {
    text-align: left;
    width: fit-content;
    margin: auto;
}
.bold {
    font-weight: bold;
    display: flex;
    align-items: center;
}
.dialog-footer {
  text-align: center;
}

.center {
  text-align: center;
}
</style>
