<template>
  <el-container class="orginazation-page-box">
    <el-aside style="width: auto; background: #fff;">
      <div class="orginazation-tree">
        <div style="padding: 20px 15px 0;">
          <div class="orginazation-title">
            <span class="title">组织架构</span>
            <el-button type="text" @click="onGoManage">组织管理</el-button>
          </div>
          <el-input
            v-model="searchVal"
            placeholder="搜索员工/手机号/组织"
            clearable
            style="margin-top: 10px;"
            @input="onSearchTree"
          >
            <el-button
              slot="prefix"
              type="text"
              icon="el-icon-search"
              style="color: #a3a3a3; padding-left: 3px;"
              @click="onSearchTree"
            />
          </el-input>
        </div>
        <OrginazationTree
          :search-val="searchVal"
          :is-loading="isLoading"
          :req-rest="getRequestRest"
          :org-item-list="orgItemList"
          :represent-item-list="representItemList"
          @onDelOrg="onDelOrg"
          @onClickNode="onClickNode"
        />
      </div>
    </el-aside>
    <el-container style="background: #fff;">
      <el-main>
        <el-header class="header-box">
          <div>
            <div class="table-title">
              <div class="node-name">{{ nodeObject.orgName }}<span v-if="nodeObject.status !== 1">（已冻结）</span></div>
              <div>
                <el-button
                  v-if="nodeObject.status === 1 && $auth('crm.orgMgmt.orgRel.adminPermissions') && isTopOrg"
                  class="mgn-r20"
                  type="text"
                  @click="onManagePermissions"
                >
                  管理员权限
                </el-button>
                <el-button
                  v-if="nodeObject.status === 1"
                  v-auth="['authorityNav']"
                  icon="el-icon-plus"
                  class="mgn-r20"
                  type="text"
                  @click="onCreateMaster"
                >
                  新增管理员账号
                </el-button>
              </div>
            </div>
            <el-table
              :data="orgManagerVo"
              max-height="250"
              stripe
              style="width: 100%; margin-top: 10px;"
            >
              <el-table-column
                prop="name"
                label=""
                align=""
                fixed
                width="180"
              >
                <!-- eslint-disable-next-line -->
                  <template slot="header" slot-scope="scope">
                  <div>
                    <div style="display: inline-block; width: 20px; height: 20px; margin-left: 3px;" />
                    <span>管理员姓名</span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div style="display: inline-block; width: 20px; height: 20px; margin-left: 3px;" />
                  <span>{{ scope.row.name || '——' }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="login"
                label="账号"
                align="center"
              >
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.login || '——' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="mobile"
                align="center"
                label="手机号"
              >
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.mobile || '——' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="userRoleId"
                align="center"
                label="权限"
              >
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.userType === 1 ? '主账号' : (scope.row.roleName || '——') }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                v-if="isShowCurrent"
                v-auth="['authorityNav']"
                align="center"
                label="操作"
              >
                <template slot-scope="scope">
                  <template v-if="nodeObject.status !== 1 && isShowCurrent">
                    <el-button
                      v-if="scope.row.userId"
                      type="text"
                      style="color: #e8312f;"
                      @click="handleDelete(scope.row)"
                    >
                      删除
                    </el-button>
                  </template>

                  <template v-else>
                    <template v-if="(!scope.row.userId && scope.row.userType === 1 && nodeObject.orgId !== orgList[0].orgId) && isShowCurrent">
                      <el-button
                        type="text"
                        @click="onCreateMaster(scope.row)"
                      >
                        新建主账号
                      </el-button>
                    </template>
                    <template v-else-if="isShowCurrent && (scope.row.userId && (scope.row.userType !== 1 || (scope.row.userType === 1 && nodeObject.orgId !== orgList[0].orgId)))">
                      <el-button
                        type="text"
                        @click="onCreateMaster(scope.row)"
                      >
                        编辑
                      </el-button>
                      <el-button
                        type="text"
                        style="color: #e8312f;"
                        @click="handleDelete(scope.row)"
                      >
                        删除
                      </el-button>
                    </template>
                  </template>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-header>

        <div class="tabs-list-box">
          <div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane :disabled="requestMap.onGetStaffList" label="在职员工" name="1" />
              <el-tab-pane :disabled="requestMap.onGetStaffList" label="已冻结" name="0" />
            </el-tabs>
          </div>
          <div v-if="!isBatchOperate" class="button-items-box">
            <div
              v-if="$auth('crm.orgMgmt.employee.addEmployee')"
              v-show="nodeObject.status === 1"
              class="dropdown-box mgn-r20"
              @mouseleave="onMouseleave"
            >
              <el-button type="primary" @click="handleCommand">
                <i class="el-icon-plus el-icon--right" />
                添加员工
                <i class="el-icon-arrow-down el-icon--right" />
              </el-button>
              <div v-show="isShowDropdown" slot="dropdown" class="dropdown-custom-item">
                <div class="custom-item" @click="onClickCommand('one')">添加单个员工</div>
                <div class="custom-item" @click="onClickCommand('most')">批量导入员工</div>
              </div>
            </div>
            <el-button
              class="mgn-r20"
              type="primary"
              plain
              @click="isBatchOperate = true"
            >
              批量操作
            </el-button>
            <el-button
              v-if="$auth('crm.orgMgmt.employee.exportEmployeeData')"
              class="mgn-r20"
              type="text"
              @click="onDownStaff"
            >
              导出数据为Excel表
            </el-button>
          </div>
          <div v-else class="button-items-box">
            <el-button
              v-if="$auth('crm.orgMgmt.employee.brtres')"
              class="mgn-r20"
              type="primary"
              plain
              @click="onRecallTask"
            >
              批量撤回资源
            </el-button>
            <el-button
              v-if="$auth('crm.orgMgmt.employee.freezeEmployee')"
              v-show="activeName==='1'"
              key="freeze"
              class="mgn-r20"
              type="primary"
              plain
              @click="onFreezeMoreStaff('freeze')"
            >
              批量冻结
            </el-button>
            <el-button
              v-if="$auth('crm.orgMgmt.employee.unfreezeEmployee')"
              v-show="activeName!=='1' && nodeObject.status === 1"
              key="cancel"
              class="mgn-r20"
              type="primary"
              plain
              @click="onFreezeMoreStaff('cancel')"
            >
              批量取消冻结
            </el-button>
            <el-button
              class="mgn-r20"
              type="primary"
              plain
              @click="onCancelOperate"
            >
              取消批量操作
            </el-button>
          </div>
        </div>
        <div style="position: relative;">
          <el-table
            ref="multipleTable"
            v-loading="requestMap.onGetStaffList"
            :data="tableData"
            row-key="key"
            :indent="32"
            :expand-row-keys="defaultExpand"
            :header-row-class-name="tableHeaderClassName"
            :row-class-name="tableRowClassName"
            tooltip-effect="dark"
            style="width: 100%;"
          >
            <el-table-column
              prop="representName"
              show-overflow-tooltip
              label="姓名"
              align="left"
              fixed
              height="50"
              width="180"
            >
              <!-- eslint-disable-next-line -->
                  <template slot="header" slot-scope="scope">
                <div>
                  <div style="display: inline-block; width: 20px; height: 20px; margin-left: 3px;" />
                  <el-checkbox
                    v-show="isBatchOperate"
                    class="pdg-r10"
                    :indeterminate="indeterAll"
                    :value="isSelectAll"
                    :disabled="!tableData.length"
                    @change="onSelectAll"
                  />
                  <span>姓名</span>
                </div>
              </template>
              <template slot-scope="scope">
                <div
                  v-if="!scope.row.ishideTip && !scope.row.isHideName"
                  style="display: inline-block; width: 20px; height: 20px; margin-left: 3px;"
                />
                <el-checkbox
                  v-if="isBatchOperate"
                  :key="scope.row.key"
                  class="pdg-r10"
                  :indeterminate="indeterAllFilter([scope.row])"
                  :value="scope.row.isSelect"
                  @change="onSelectionChange($event,scope.row)"
                />
                <span v-if="!scope.row.isHideName && isBatchOperate">{{ scope.row.representName }}</span>
                <span
                  v-if="!scope.row.isHideName && !isBatchOperate"
                  class="is-pointor"
                  style="color: #3d61e3;"
                  @click="handleStaffDetail(scope.$index, scope.row)"
                >{{ scope.row.representName }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="orgName"
              label="组织"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div class="org-name-box" :class="{'has-child':scope.row.nodeArr}">
                  <el-tooltip
                    v-if="!scope.row.ishideTip && scope.row.orgStatus !== 1"
                    effect="light"
                    content="该组织已被冻结"
                    placement="bottom"
                  >
                    <img src="@/assets/nav/forbid.png" alt="">
                  </el-tooltip>
                  {{ scope.row.orgName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="representMobile"
              align="center"
              label="手机号"
            />
            <el-table-column
              prop="doctorCount"
              align="center"
              label="客户数"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="handleStaffDetail(scope.$index, scope.row)">
                  {{ scope.row.doctorCount }}
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              prop=""
              align="center"
              width="200"
              label="操作"
            >
              <template slot-scope="scope">
                <div v-if="!scope.row.nodeArr">
                  <template v-if="activeName === '0'">
                    <el-button
                      type="text"
                      @click="handleStaffDetail(scope.$index, scope.row)"
                    >
                      市场交接
                    </el-button>
                    <el-button
                      v-if="$auth('crm.orgMgmt.employee.unfreezeEmployee')"
                      type="text"
                      @click="handleFreeze(scope.row,true)"
                    >
                      取消冻结
                    </el-button>
                  </template>
                  <template v-else>
                    <el-button
                      v-if="$auth('crm.orgMgmt.employee.editEmployee')"
                      type="text"
                      @click="handleEdit(scope.$index, scope.row)"
                    >
                      编辑员工
                    </el-button>
                    <el-button
                      type="text"
                      @click="handleStaffDetail(scope.$index, scope.row)"
                    >
                      市场交接
                    </el-button>
                    <el-button
                      v-if="$auth('crm.orgMgmt.employee.freezeEmployee')"
                      type="text"
                      style="color: #e8312f;"
                      @click="handleFreeze(scope.row,false,!scope.row.codeCount && !scope.row.doctorCount)"
                    >
                      {{ !scope.row.codeCount && !scope.row.doctorCount ? '删除' : '冻结' }}
                    </el-button>
                  </template>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          class="pagination-box"
          :current-page="pageNo"
          :page-size="pageSize"
          :total="total"
          background
          hide-on-single-page
          small
          layout="total, prev, pager, next"
          @current-change="onCurrentChange"
        />
      </el-main>
    </el-container>
    <ChangeOrg v-if="isShowChangeOrg" :is-show-pop="isShowChangeOrg" @onClose="(val) => {isShowChangeOrg = val}" />
    <ImportExcelDialog
      v-if="isShowImport"
      :is-show-pop="isShowImport"
      :model-type="modelType"
      @onComplete="onComplete"
      @onClose="(val) => {isShowImport = val}"
    />
    <RecallRepresentTask
      v-if="isShowRecallTask"
      :is-show-pop="isShowRecallTask"
      :multiple-selection="multipleSelection"
      :form-obj="formObj"
      @onConfirm="onCheckIsSelectAll"
      @onClose="(val) => {isShowRecallTask = val}"
    />
    <FreezeDialog
      v-if="isShowFreeze"
      :is-show-pop="isShowFreeze"
      :is-more="isMore"
      :is-freeze="isFreeze"
      :multiple-selection="multipleSelection"
      :form-obj="formObj"
      @onFreezeStaff="onFreezeStaff"
      @onClose="(val) => {isShowFreeze = val}"
    />
    <DelRepresentTip
      v-if="isShowDelRepresent"
      :is-show-pop="isShowDelRepresent"
      :is-more="isMore"
      :is-freeze="isFreeze"
      :multiple-selection="multipleSelection"
      :form-obj="formObj"
      @onConfirm="onFreezeStaff"
      @onClose="(val) => {isShowDelRepresent = val}"
    />
    <AssistAccount
      v-if="isShowAssist"
      :is-show-pop="isShowAssist"
      :form-obj="formObj"
      @onEditManager="onEditManager"
      @onClose="(val) => {isShowAssist = val}"
    />
    <DeleteManagerDialog
      v-if="isShowDeleteManager"
      :is-show-pop="isShowDeleteManager"
      :form-obj="formObj"
      @onDelManager="onEditManager"
      @onClose="(val) => {isShowDeleteManager = val}"
    />
    <AddStaffDialog
      v-if="isShowAdd"
      :is-show-pop="isShowAdd"
      :title="title"
      :form="form"
      :filter-org-ids="filterOrgIds"
      @onClose="(val) => {isShowAdd = val}"
      @update="onGetStaffList"
      @onChangeOrg="onChangeOrg"
    />
    <ChangeOrgDialog
      v-if="showChangeOrgDialog"
      :show-change-org-dialog="showChangeOrgDialog"
      :from-og-id="fromOgId"
      :represent-id="form.representId"
      :org-list="currentOrgList"
      @onCloseChange="onCloseChange"
      @onChange="onChange"
    />
  </el-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OrginazationTree from './components/OrginazationTree'
import ChangeOrg from './components/ChangeOrg'
import AddStaffDialog from './components/AddStaffDialog'
import RecallRepresentTask from './components/RecallRepresentTask'
import FreezeDialog from './components/FreezeDialog'
import DelRepresentTip from './components/DelRepresentTip'
import ChangeOrgDialog from './components/ChangeOrgDialog'
import AssistAccount from './components/AssistAccount'
import DeleteManagerDialog from './components/DeleteManagerDialog'
import ImportExcelDialog from '@/baseComponents/ImportExcelDialog'
import {createDriver, handleDriverRecord} from '@/utils/driver'

export default {
  name: 'Orginazation',
  components: {
    OrginazationTree,
    ChangeOrg,
    ImportExcelDialog,
    RecallRepresentTask,
    FreezeDialog,
    DelRepresentTip,
    AssistAccount,
    AddStaffDialog,
    DeleteManagerDialog,
    ChangeOrgDialog
  },
  data() {
    return {
      currentLogin: {},
      searchVal: '',
      isLoading: true,
      timer: null,
      staffSearchClick: '',
      pageNo: 1,
      pageSize: 20,
      total: 0,

      nodeObject: {},
      isDowning: false,

      orgName: '',
      managerList: [],

      isAll: false,

      activeName: '1',
      tableList: [],
      initTableList: [],
      selectList: [],
      isSelectAll: false,
      indeterAll: false,
      indeterChild: false,
      formObj: {},

      isShowDropdown: false,

      isBatchOperate: false,

      isShowChangeOrg: false,

      isShowRecallTask: false,          // 批量撤回资源弹窗

      isShowFreeze: false,              // 批量冻结/取消弹窗
      isShowDelRepresent: false,
      isFreeze: false,
      isMore: false,

      isShowDeleteManager: false,

      isShowAssist: false,

      isShowImport: false,
      modelType: 'addStaff',
      isShowAdd: false,
      title: '',

      orgItemList: [],
      representItemList: [],
      currentOrgList: [],
      showChangeOrgDialog: false,
      form: {},
      fromOgId: '',
      requestMap: {},
      filterOrgIds: []
    }
  },
  computed: {
    ...mapGetters(['orgList', 'isTopOrg']),
    superRole() {
      return this.currentLogin.userType === 1
    },
    allRole() {
      return this.currentLogin.userRoleId === '4'
    },
    isShowCurrent() {
      return (this.superRole || this.allRole && this.nodeObject.orgId !== this.orgList[0].orgId)
    },
    tableDom() {
      return this.$refs.multipleTable
    },
    tableData: {
      get() {
        return this.tableList
      },
      set(list) {

        const hasSelectList = []    // 已选数据
        const tableList = []
        list?.map((item, ind) => {
          let obj = {
            representId: item.representId,
            representMobile: item.representMobile,
            representName: item.representName,
            key: item.representId,
            isSelect: item.isSelect,
            children: [],
            rowIndex: ind
          }
          const children = []
          if (item.orgInfoVOS?.length > 1) {
            obj = Object.assign(obj, {
              orgName: '所属于多个组织',
              ishideTip: true,
              nodeArr: true
            })
            for (const it of item.orgInfoVOS || []) {
              const newObj = {
                ...item,
                key: `${item.representId  }-${  it.orgId}`,
                isHideName: true,
                ...it,
                rowIndex: ind
              }
              children.push(newObj)
              if (it.isSelect) {   // 已选数据
                hasSelectList.push(newObj)
              }
            }
            obj.children = children
          } else {
            const it = item.orgInfoVOS && item.orgInfoVOS[0]
            obj = {...item, key: item.representId, ...it, rowIndex: ind}

            if (item.isSelect) {   // 已选数据
              hasSelectList.push(obj)
            }
          }
          tableList.push(obj)
        })
        this.multipleSelection = hasSelectList      // 已选数据
        this.tableList = tableList
      }
    },
    defaultExpand() {
      return [this.tableData[0]?.key]
    },

    orgManagerVo: {
      get() {
        let isHasMaster = false
        this.managerList?.map((item, ind) => {
          if (item.userType === 1) {
            isHasMaster = true
            this.managerList.splice(ind, 1)
            this.managerList.unshift(item)
          }
        })
        if (this.nodeObject?.orgId === this.orgList[0]?.orgId) {
          return this.managerList
        }

        return isHasMaster ? (this.managerList.length ? this.managerList : [{userType: 1}]) : [{userType: 1}, ...this.managerList]
      },
      set(list) {
        this.managerList = list || []
      }
    },
    multipleSelection: {
      get() {
        return this.selectList
      },
      set(list) {
        this.selectList = list
        this.onClearNode()
        if (list.length) {
          this.onCreateNode()
        }
      }
    },
    getRequestRest() {
      let rest = false
      for (const value of Object.values(this.requestMap)) {
        if (value) {
          rest = value
          return rest
        }
      }
      return rest
    }
  },
  watch: {
    $route(to, from) {
      if (from.name === 'StaffDetail') {
        this.onFreezeStaff()
      }
    },
    searchVal(newVal) {
      if (!newVal) {
        this.orgItemList = []
        this.representItemList = []
      }
    },
    tableData: {
      deep: true,
      handler(newVal) {
        this.indeterAll =  this.indeterAllFilter(newVal)
      }
    }

  },
  mounted() {
    setTimeout(() => {
      this.guide()
    }, 1000)
  },
  methods: {
    ...mapActions(['getAccounts', 'orgStaffList', 'getOrgSearchList', 'representsDownload', 'getOrgInfoList', 'needNoticeDelRepresent', 'getCurrentLoginInfo', 'getRepresentInfo']),
    guide() {
      const cssSelectPath = '.main .table-title div + div > .el-button'
      const targetDom = document.querySelector(cssSelectPath)
      if (targetDom && this.$auth('crm.orgMgmt.orgRel.adminPermissions') && this.isTopOrg) {
        const firstButton = document.createElement('button')
        firstButton.innerText = '跳过'
        const driver = createDriver({
          popoverClass: 'customize-driver',
          doneBtnText: '下一步',
          showButtons: ['next'],
          onNextClick: () => {
            this.onManagePermissions()
            driver.destroy()
          },
          onPopoverRender: (popover, { state }) => {
            const nextButton = popover.footerButtons.querySelector('.driver-popover-next-btn')
            const progressText = state.activeStep?.popover?.progressText
            if (nextButton && progressText && !(/\(\s*\d+\s*\/\s*\d+\s*\)/.test(nextButton.innerHTML))) {
              nextButton.innerHTML += '(1/3)'
            }
            // 添加到第一个按钮
            nextButton.prepend(firstButton)
          },
          steps: [
            {
              element: cssSelectPath,
              popover: {
                description: '现在支持自定义管理员权限啦～'
              }
            }
          ]
        })

        firstButton.addEventListener('click', () => {
          const { record } = handleDriverRecord()
          record('a89c26578d34ddb28c93bff83fcfa2d4')
          driver.destroy()
        })
        driver?.drive()
      }
    },
    tableHeaderClassName() {
      return 'table-header'
    },
    tableRowClassName({row}) {
      if (row.rowIndex % 2 === 0) {
        return 'fff-row'
      } else {
        return 'faf-row'
      }
    },
    onGetInfo(orgId) {
      this.$set(this.requestMap, 'onGetInfo', true)
      this.getOrgInfoList({orgId: orgId}).then(res => {
        this.$set(this.requestMap, 'onGetInfo', false)
        const {data = {}} = res
        this.$set(this.nodeObject, 'status', data.status)
      }, () => {
        this.$set(this.requestMap, 'onGetInfo', false)
      })
    },
    onDownStaff() {
      if (this.isDowning) return
      this.isDowning = true
      const data = {
        status: this.activeName,
        childOrgId: this.nodeObject.orgId
      }
      this.representsDownload(data).then(res => {
        this.$message.success(res.data)
        this.isDowning = false
      }, rea => {
        this.isDowning = false
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    onCheckIsSelectAll(options = false) {
      this.isSelectAll = options
      const list = []
      this.initTableList.map(item => {
        item.isSelect = this.isSelectAll
        if (item.orgInfoVOS?.length) {
          item.orgInfoVOS.map(it => {
            it.isSelect = this.isSelectAll
            list.push(it)
          })
        } else {
          list.push(item)
        }
      })
      this.tableData = this.initTableList
    },
    indeterAllFilter(data = []) {      // 节点是否半选
      let hasSelect = false
      let hasNoSelect = false
      for (const item of data) {
        if (!item.nodeArr) {
          if (item.isSelect) {
            hasSelect = true
          } else {
            hasNoSelect = true
          }
        } else {
          for (const it of item.children) {
            if (it.isSelect) {
              hasSelect = true
            } else {
              hasNoSelect = true
            }
          }
        }
        if (hasSelect && hasNoSelect) {
          return true
        }
      }
      return false
    },
    onSearchTree() {
      this.timer && clearTimeout(this.timer)
      if (!this.searchVal.trim()) return
      this.timer = setTimeout(() => {
        this.isLoading = true
        this.orgItemList = []
        this.representItemList = []
        this.getOrgSearchList({keyword: this.searchVal.trim()}).then(res => {
          this.isLoading = false
          this.orgItemList = res.data?.orgItemList || []
          this.representItemList = res.data?.representItemList || []
        }, rea => {
          this.isLoading = false
          this.$message.error(rea.message)
        })
      }, 500)
    },
    onClearNode() {
      if (!this.tableDom) return
      const targetDom = this.tableDom.$el.getElementsByClassName('el-table__header-wrapper')[0]
      const children = targetDom.getElementsByClassName('table-select-text')[0]
      if (children) {
        targetDom.removeChild(children)
      }
    },
    onManagePermissions() {
      this.$router.push({
        name: 'PermissionManagement'
      })
    },
    onCreateMaster(data) {
      this.isShowAssist = true
      this.formObj = {...this.nodeObject, ...data}
    },
    onEditManager() {
      this.onGetManagerList()
    },
    onGoManage() {
      this.$router.push('/orginazation/manage')
    },
    onCreateNode() {
      this.$nextTick(() => {
        if (!this.tableDom) return
        const targetDom = this.tableDom.$el.getElementsByClassName('el-table__header-wrapper')[0]
        const divTemp = document.createElement('div')
        divTemp.onselectstart = function() { return false }
        divTemp.className = 'table-select-text'

        // let span1 = document.createElement('span')
        // let currentPage = this.isAll ? '全部' : '当前'
        // let total = this.isAll ? this.total : this.multipleSelection?.length
        // let tipText = this.isAll ? '如果仅选当前页面' : '如要全选所有'
        // span1.innerHTML = `<span>已选${currentPage}页面共${total}条数据，${tipText}数据`
        // let span2 = document.createElement('span')
        // span2.style = "color:#3D61E3;cursor:pointer;"
        // span2.innerHTML = '点击此处'
        // span2.onclick  = this.onChangeAll
        // let span3 = document.createElement('span')
        // let text = this.isAll ? '选择' : '全选'
        // span3.innerHTML = `${text}`
        // span1.appendChild(span2)
        // span1.appendChild(span3)

        const span1 = document.createElement('span')
        span1.innerHTML = `已选当前页面共${this.multipleSelection?.length}条数据`

        divTemp.appendChild(span1)

        targetDom.appendChild(divTemp)
      })
    },
    onSelect(selection, row) {
      console.log(selection, row, '============ selection,row ============== ')

    },
    onSelectAll(selection) {
      console.log(selection, this.initTableList, this.tableData, '============ onSelectAll ============== ')
      this.onCheckIsSelectAll(selection)
    },
    onSelectionChange(selection, row) {
      console.log(selection, row, this.initTableList, ' ============== onSelectionChange ================')
      //  获取节点 key
      const repId = row.key.split('-')[0]
      // 节点及其子级是否勾选
      for (const item of this.initTableList) {
        if (row.key === item.key) {
          if (item.orgInfoVOS?.length) {
            for (const it of item.orgInfoVOS || []) {
              this.$set(it, 'isSelect', selection)
            }
          }
          break
        } else {
          if (item.orgInfoVOS?.length) {
            for (const it of item.orgInfoVOS || []) {
              if (row.key === it.key) {
                this.$set(it, 'isSelect', selection)
                break
              }
            }
          }
        }
      }
      // 节点父节点是否勾选
      for (const item of this.initTableList) {
        if (item.key === repId) {
          let hasSelect = false
          let hasnoSelect = false
          for (const it of item.orgInfoVOS || []) {
            if (it.isSelect) {
              hasSelect = true
            } else {
              hasnoSelect = true
            }
            if (hasSelect && hasnoSelect) {
              this.$set(item, 'isSelect', false)
              break
            }
          }
          if (!hasSelect && hasnoSelect) {
            this.$set(item, 'isSelect', false)
          } else if (hasSelect && !hasnoSelect) {
            this.$set(item, 'isSelect', true)
          }
          break
        }
      }
      // 全部节点是否勾选
      let hasNoSelect = false
      for (const item of this.initTableList) {
        if (!item.isSelect) {
          this.isSelectAll = false
          hasNoSelect = true
          break
        }
        if (item.orgInfoVOS?.length) {
          for (const it of item.orgInfoVOS || []) {
            if (!it.isSelect) {
              this.isSelectAll = false
              hasNoSelect = true
              break
            }
          }
        }
      }
      this.isSelectAll = !hasNoSelect

      this.tableData = this.initTableList

    },
    onChangeAll() {
      console.log('-------------- change all --------------------')
      this.isAll = !this.isAll
      this.onClearNode()
      this.onCreateNode()
    },
    onRecallTask() {
      if (!this.multipleSelection.length) {
        this.$message({
          message: '请选择需要撤回资源的员工',
          type: 'error'
        })
        return
      }
      this.isShowRecallTask = true
    },
    onFreezeMoreStaff(type) {
      this.isMore = true
      this.isFreeze = type !== 'freeze'
      if (!this.multipleSelection.length) {
        this.$message({
          message: type === 'freeze' ? '请选择需要冻结的员工' : '请选择需要取消冻结的员工',
          type: 'error'
        })
        return
      }
      this.isShowFreeze = true
    },
    onCancelOperate() {
      this.isBatchOperate = false
      this.isMore = false
      this.onCheckIsSelectAll()
    },
    onFreezeStaff() {
      this.onGetStaffList()
    },
    onAddKey(initList) {
      const list = []
      for (const item of JSON.parse(JSON.stringify(initList))) {
        const obj = item
        obj.key = item.representId
        if (item.orgInfoVOS?.length) {
          for (const it of item.orgInfoVOS || []) {
            it.key = `${item.representId  }-${  it.orgId}`
          }
        }
        list.push(obj)
      }
      this.initTableList = list
    },
    onComplete() {
      this.pageNo = 1
      this.onGetStaffList()
    },
    onGetStaffList() {
      this.$set(this.requestMap, 'onGetStaffList', true)
      this.orgStaffList({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        status: this.activeName,
        childOrgId: this.nodeObject.orgId
      }).then(res => {
        this.$set(this.requestMap, 'onGetStaffList', false)
        const {pageData = [], total = 0} = res.data || {}
        this.onAddKey(pageData)
        this.onCheckIsSelectAll()
        this.total = total
      }, () => {
        this.$set(this.requestMap, 'onGetStaffList', false)
      })
    },
    onGetManagerList() {
      this.$set(this.requestMap, 'onGetManagerList', true)
      this.getAccounts({targetOrgId: this.nodeObject.orgId}).then(res => {
        this.$set(this.requestMap, 'onGetManagerList', false)
        this.orgManagerVo = res.data || []
        this.orgName = this.orgManagerVo.length && this.orgManagerVo[0].name
      }, () => {
        this.$set(this.requestMap, 'onGetManagerList', false)
        this.orgManagerVo = []
      })
    },
    onClickNode(data) {
      this.nodeObject = data
      this.pageNo = 1
      this.onGetLoginInfo()
      this.onGetInfo(data.orgId)
      this.onGetManagerList()
      this.onGetStaffList()
    },
    onGetLoginInfo() {
      this.getCurrentLoginInfo().then(res => {
        this.currentLogin = res || {}
      })
    },
    onDelOrg() {
      this.onSearchTree()
    },

    onCurrentChange(option) {
      console.log(option)
      this.pageNo = option
      this.onGetStaffList()
    },
    handleEdit(index, row) {
      this.getRepresentInfo({ mobile: row.representMobile }).then(res => {
        this.form = { ...res.data, orgInfoVOS: res.data.orgInfoVOS.filter(v => v.status)}
        this.filterOrgIds = []
        res.data.orgInfoVOS.map(v => {
          if (v.status < 1) {
            this.filterOrgIds.push(v.orgId)
          }
        })
        this.isShowAdd = true
        this.title = '编辑员工'
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    handleDelete(data) {
      this.formObj = {...this.nodeObject, ...data}
      this.isShowDeleteManager = true
    },
    handleStaffDetail(index, row) {
      this.$router.push({path: `/orginazation/staff/${row.representId}?mobile=${row.representMobile}&orgId=${row.orgId || ''}`})
    },

    handleFreeze(data, isFreeze = false, isDel = false) {
      console.log(data, isFreeze, isDel)

      if (data.orgStatus !== 1) {
        return this.$message.error('该组织处于冻结状态，不支持操作！')
      }

      this.isMore = false
      this.formObj = {...this.nodeObject, ...data}
      this.isFreeze = isFreeze
      if (isDel) {
        this.isShowDelRepresent = true
      } else {
        this.isShowFreeze = true
      }
    },
    handleClick() {
      this.isBatchOperate = false
      this.isMore = false
      this.pageNo = 1
      this.onGetStaffList()
    },
    onMouseenter() {
      this.isShowDropdown = true
    },
    onMouseleave() {
      this.isShowDropdown = false
    },
    handleCommand() {
      this.isShowDropdown = !this.isShowDropdown
    },
    onClickCommand(type) {
      this.isShowDropdown = false
      if (type === 'most') {
        this.isShowImport = true
        this.modelType = 'addStaff'
      } else {
        this.form = {
          orgId: this.nodeObject.orgId
        }
        this.mobile = ''
        this.isShowAdd = true
        this.title = '添加员工'
      }
    },
    onChangeOrg(currentOrgList, id, orgInfoVOS, representId) {
      this.fromOgId = id
      this.currentOrgList = currentOrgList
      this.isShowAdd = false
      this.showChangeOrgDialog = true
      this.form.orgInfoVOS = orgInfoVOS
      this.form.representId = representId
    },
    onCloseChange() {
      this.showChangeOrgDialog = false
      this.isShowAdd = true
    },
    onChange(orgId, orgName) {
      this.form.orgInfoVOS = this.form.orgInfoVOS.map(i => {
        if (i.orgId === this.fromOgId) {
          i.orgId = orgId
          i.orgName = orgName
          i.isSelect = true
        }
        return i
      })
      this.onCloseChange()
    }
  }
}
</script>

<style>
    .el-table th.el-table__cell {
      background-color: #eee;
    }
    .el-table .fff-row {
      background-color: #fff;
    }
    .el-table .faf-row {
      background-color: #fafafa;
    }
</style>

<style lang="scss" scoped>
::v-deep .el-table .el-table__cell {
  padding: 5px 0;
  height: 51px;
}
.orginazation-page-box {
  margin: 10px;
  ::v-deep .el-aside {
    text-align: center;
    border-right: 1px solid #f5f7fb;
  }
}
.orginazation-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    font-size: 18px;
    font-weight: 600;
    color: #0f0f0f;
  }
}
.orginazation-tree {
  width: 100%;
  margin: 0 auto;
  background: #fff;
  ::v-deep .el-input__inner {
    background-color: #fafafa;
    border-radius: 20px;
  }
}
.node-name {
  color: #0f0f0f;
  font-size: 18px;
  font-weight: bold;
  line-height: 36px;
}
.header-box {
  height: auto !important;
  padding: 0 0 20px;
}
.team-title {
  color: #0f0f0f;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}
.table-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #5a5a5a;
  font-size: 14px;
}
.tabs-list-box {
  position: relative;
}
.button-items-box {
  position: absolute;
  top: 5px;
  right: 0;
  @media screen and (max-width: 768px) {
    position: relative;
    top: 0;
  }
}
.dropdown-box {
  display: inline-block;
  position: relative;
}
.dropdown-custom-item {
  position: absolute;
  left: 0;
  z-index: 30;
  width: 100%;
  background: #fbfbfb;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 1px;
  border: 1px solid #e1e1e1;
}
.custom-item {
  height: 32px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
}
.custom-item:hover {
  background: rgba(61, 97, 227, 0.12);
  color: #3d61e3;
}
.pagination-box {
  text-align: right;
  margin-top: 20px;
}
::v-deep .table-select-text {
  width: 100%;
  height: 25px;
  line-height: 25px;
  background: rgba(255, 153, 0, 0.15);
  padding: 0 30px;
}
.has-child {
  color: #969696;
}
.org-name-box {
  display: inline;
  img {
    vertical-align: middle;
  }
}
.mgn-r20 {
  margin-right: 20px;
}
.pdg-r10 {
  padding-right: 10px;
}
</style>
