<template>
  <div class="delete-dialog-box">
    <el-dialog v-if="!isShowTip"  :title="title" :visible.sync="dialogFormVisible">
      <div class="freeze-info left-align">
        <div class="bold">
            <img src="@/assets/iconImg/fail.png" style="width: 14px;margin-right: 3px;" />
            <span>确认删除{{formObj.orgName}}吗？</span>
        </div>
        <div style="color: #5A5A5A;">一旦删除后将无法恢复</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">删 除</el-button>
      </div>
    </el-dialog>
    <el-dialog v-else title="温馨提示" :visible.sync="dialogFormVisible">
      <div class="freeze-info">
        <div>{{message}}</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogFormVisible = false">我知道了</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: "DeleteDialog",
  props: {
    isShowPop: {
      type: Boolean,
      default: false
    },
    formObj: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    dialogFormVisible: {
      get() {
        this.open = this.isShowPop
        this.isShowTip = false
        return this.isShowPop
      },
      set(val) {
        this.open = val
        this.$emit('onClose',val)
      }
    }
  },
  data() {
    return {
      open: false,
      title: '删除组织',
      isShowTip: false,
      message: ''
    }
  },
  methods: {
    ...mapActions(['onCreateDepartment','getOrgList','onDelDepartment']),
    onDelOrg() {
      this.onDelDepartment({orgId: this.formObj.orgId}).then(res => {
        if(res.data === '删除成功') {
          this.dialogFormVisible = false
          this.getOrgList()
          this.$message.success("删除成功！")
          this.$emit("onDelOrg")
        } else {
          this.message = res.data
          this.isShowTip = true

        }
      },rea => {
        this.$message({
          message:rea.message,
          type:"error"
        })
      })
    },
    submitForm() {
      this.onDelOrg()
    },
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.delete-dialog-box {
    ::v-deep .el-dialog {
        width: fit-content;
        min-width: 460px;
    }
  ::v-deep .el-dialog .el-dialog__header {
    background-color: #ffffff;
    text-align: left;
    border-bottom: 1px solid #e1e1e1;
    .el-dialog__title {
      color: #0F0F0F;
      font-size: 14px;
    }
    .el-dialog__close {
      color: #909399 !important;
    }
  }
}
.freeze-info {
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  padding: 36px 0 30px;
  color: #0F0F0F;
  line-height: 22px;
}
.left-align {
    text-align: left;
    width: fit-content;
    margin: auto;
}
.bold {
    font-weight: bold;
    display: flex;
    align-items: center;
}
.dialog-footer {
  text-align: center;
}
</style>
