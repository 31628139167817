<template>
  <el-dialog
    class="customize-el-dialog"
    :title="title"
    v-bind="$attrs"
    :visible.sync="dialogFormVisible"
    v-on="$listeners"
  >
    <div class="steps-box">
      <div>
        <div class="out-line-left" :class="{ 'is-already': isUpload }" />
        <div class="pdg-t10" :class="{ 'is-already-text': isUpload }">
          1.上传Excel
        </div>
      </div>
      <div>
        <div class="out-line-center" :class="{ 'is-already': isCheck }" />
        <div class="pdg-t10" :class="{ 'is-already-text': isCheck }">
          2.检验数据
        </div>
      </div>
      <div>
        <div class="out-line-right" :class="{ 'is-already': isImport }" />
        <div class="pdg-t10" :class="{ 'is-already-text': isImport }">
          3.导入数据
        </div>
      </div>
    </div>
    <!-- 上传Excel -->
    <div v-show="isUpload && !isCheck && !isImport" class="upload-content-box">
      <div class="upload-model-box">
        <span>上传文件：</span>
        <div>
          <el-upload
            :action="uploadApi"
            :headers="headers"
            name="file"
            :show-file-list="false"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx, .xls"
            list-type="picture-card"
            :on-progress="onUploadProgress"
            :on-success="onUploadSuccess"
            :on-error="onUploadError"
          >
            <template>
              <div class="upload-cover">
                <img v-if="uploadFlag" :src="imageUrl" alt="">
                <img
                  v-else-if="isSuccess"
                  :src="require('@/assets/iconImg/success.png')"
                  alt=""
                >
                <img
                  v-else
                  :src="require('@/assets/iconImg/fail.png')"
                  alt=""
                >
                <span>{{ uploadText }}</span>
              </div>
            </template>
          </el-upload>
          <div v-show="uploadProgress" class="out-progress">
            <div class="in-progress" :style="{ width: uploadProgress + '%' }" />
          </div>
        </div>
      </div>
      <div class="remark">
        <span>请</span>
        <el-link type="primary" @click="onDownModal">点击此处</el-link>
        <span>下载{{ modelTitle }}模板，填写后完成上传文件</span>
      </div>
    </div>
    <!-- 检测数据 -->
    <div v-show="isUpload && isCheck && !isImport">
      <div v-if="!isCheckPass" class="check-info-box">
        <div style="text-align: center;">
          <img
            :src="require('@/assets/common/icon_failed.png')"
            alt=""
            style="width: 50px; margin-bottom: 25px;"
          >
        </div>
        <span>共导入{{ responseObj.totalCount || 0 }}条数据，其中{{
          responseObj.successCount || 0
        }}条有效数据校验成功，{{
          responseObj.errorCount || 0
        }}条有效数据校验失败。请</span>
        <el-button type="text" size="mini" @click="onDownError">
          下载文件
        </el-button>
        <span>查看失败原因，纠查后重新上传校验。</span>
        <template v-if="isHasHelp">
          <span>如有疑问，请</span>
          <el-button type="text" size="mini" @click="onJumpHelp">
            点击此处
          </el-button>
          <span>查看解决方法。</span>
        </template>
      </div>
      <div v-else class="check-info-box">
        <div style="text-align: center;">
          <img
            :src="require('@/assets/common/icon_success.png')"
            alt=""
            style="width: 50px; margin-bottom: 25px;"
          >
        </div>
        <span>共导入{{ responseObj.totalCount }}条数据，其中{{
          responseObj.successCount
        }}条有效数据校验成功，{{
          responseObj.errorCount
        }}条有效数据校验失败。</span>
        <div class="vHtml" v-html="tipContent" />
      </div>
    </div>
    <!-- 导入数据 -->
    <div v-show="isUpload && isCheck && isImport" class="check-info-box">
      <img
        src="@/assets/common/icon_success.png"
        alt=""
        style="width: 50px; margin-bottom: 25px;"
      >
      <div>{{ title }}成功</div>
      <div>共{{ responseObj.successCount }}条数据</div>
    </div>
    <div
      v-show="isUpload && isCheck && !isImport"
      slot="footer"
      class="dialog-footer upload-btn-box"
    >
      <el-button v-if="!isCheckPass" type="primary" @click="onBackSteps">
        重新上传校验
      </el-button>
      <el-button
        v-else
        type="primary"
        :loading="loading"
        @click="onConfirm"
      >
        {{ btnText }}
      </el-button>
    </div>
    <div
      v-show="isUpload && isCheck && isImport"
      slot="footer"
      class="dialog-footer"
    >
      <el-button type="primary" @click="onCloseModel">完成</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mapActions } from 'vuex'
import { getToken } from '@/utils/auth'
import API from '@/apis/api-types'
import { saveAs } from 'file-saver'
export default {
  name: 'ImportExcelDialog',
  components: {},
  props: {
    isShowPop: {
      type: Boolean,
      default: false
    },
    itemObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    modelType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      initModelType: {
        addOrg: {
          title: '批量导入组织', // 弹窗标题
          modelTitle: '批量组织', // 下载模版名称
          uploadApi: API.uploadBatchImportOrg, // 上传地址
          isHasHelp: true, // 是否有帮助中心
          url: '/help/detail/d98d4b7323ca39e6efa9d0b00410c4ba', // 帮助中心地址
          downFn: this.batchImportOrgModel, // 下载模版函数
          confirmFn: this.uploadBatchImportOrgConfirm, // 导入函数
          completeFn: null, // 完成函数
          isGoDownCenter: false, // 是否去下载中心
          reqObj: {} // 额外的请求参数
        },
        addStaff: {
          title: '批量导入员工',
          modelTitle: '批量员工',
          uploadApi: API.uploadBatchImportRepresent,
          isHasHelp: true,
          url: '/help/detail/0586d01625cd8e52ebb8f3265cca20bb',
          downFn: this.batchImportRepresentModel,
          confirmFn: this.uploadBatchImportRepresentConfirm,
          completeFn: null,
          isGoDownCenter: false
        },
        allotRepresent: {
          title: '批量导入代表',
          modelTitle: '批量代表',
          uploadApi: API.uploadBatchAttributeRepresent,
          isHasHelp: false,
          downFn: this.getAttributeRepresentFile,
          confirmFn: this.getExcelResultData,
          completeFn: null,
          isGoDownCenter: true
        },
        allotDoctor: {
          title: '批量导入医生',
          modelTitle: '批量医生',
          uploadApi: `${API.uploadBatchAttributeDoctor}?sceneId=${this.$route.params.id}&sceneType=${this.$route.params.sceneType}`,
          isHasHelp: false,
          downFn: this.getAttributeDoctorFile,
          confirmFn: this.getExcelResultData,
          completeFn: null,
          isGoDownCenter: true
        },
        bulkPayment: {
          title: '批量导入支付审批',
          modelTitle: '批量支付审批',
          uploadApi: API.uploadBatchPayLiveFee,
          isHasHelp: false,
          url: '',
          downFn: this.downBatchPayLiveFee,
          confirmFn: this.confirmBatchPayLiveFee,
          completeFn: null,
          isGoDownCenter: true,
          reqObj: {
            startAt: this.itemObj.startDate,
            endAt: this.itemObj.endDate
          }
        },
        taskApprover: {
          title: '批量导入任务审批',
          modelTitle: '批量任务审批',
          uploadApi: API.uploadBatchSceneTaskAudit,
          isHasHelp: false,
          url: '',
          downFn: this.downBatchSceneTaskAudit,
          confirmFn: this.confirmBatchSceneTaskAudit,
          completeFn: null,
          isGoDownCenter: true,
          reqObj: this.itemObj
        },
        bulkUploadDoctor: {
          title: '批量新建客户',
          modelTitle: '批量新建客户',
          uploadApi: API.batchImportOrgDoctorModelFile,
          isHasHelp: false,
          url: '',
          downFn: async() =>
            saveAs(
              'https://cdnfile.yaomaitong.net/excel/%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E5%8C%BB%E7%94%9F%E6%A8%A1%E6%9D%BF%20.xlsx'
            ),
          confirmFn: params => {
            return this.$axios.get(API.batchImportDoctorInfo, { params })
          },
          completeFn: null,
          isGoDownCenter: false,
          reqObj: {}
        },
        orgGradeSpeakerModel: {
          title: '批量导入播客分级审批',
          modelTitle: '批量播客分级审批',
          uploadApi: API.uploadBatchImportOrgGradeSpeakerModel,
          isHasHelp: false,
          url: '',
          downFn: this.importCommonDownModel,
          confirmFn: this.confirmBatchImportOrgGradeSpeakerModel,
          completeFn: null,
          isGoDownCenter: false,
          reqObj: null
        }
      },
      uploadData: {},
      isUpload: true,
      isCheck: false,
      isCheckPass: false,
      isImport: false,
      headers: {
        token: getToken()
      },
      uploadFlag: true,
      isSuccess: false,
      uploadProgress: 0,
      responseObj: {},
      file: null,
      loading: false,
      uploadedList: []
    }
  },
  computed: {
    title() {
      return this.initModelType[this.modelType]?.title || '批量导入'
    },
    modelTitle() {
      return this.initModelType[this.modelType]?.modelTitle || '批量'
    },
    uploadApi() {
      return this.initModelType[this.modelType]?.uploadApi || ''
    },
    isHasHelp() {
      return this.initModelType[this.modelType]?.isHasHelp
    },
    btnText() {
      switch (this.modelType) {
        case 'bulkPayment':
          return '开始支付'
        case 'taskApprover':
          return '开始分配'
        default:
          return '开始导入'
      }
    },
    dialogFormVisible: {
      get() {
        return this.isShowPop
      },
      set(val) {
        if (this.loading) return true
        this.$emit('onClose', val)
      }
    },
    imageUrl() {
      return require('@/assets/common/file_excel.png')
    },
    uploadText() {
      return '上传文件'
    },
    tipContent() {
      switch (this.modelType) {
        case 'bulkPayment': {
          let text = ''
          if (
            this.responseObj.totalAmount > this.responseObj.disposableAmount
          ) {
            text = '您的帐户余额不足，暂不支持此次批量支付'
          } else {
            text = '您的帐户余额充足，若无异议，请点击“开始支付”'
          }
          const str = `
            <div>
              <div>本次将支付视频采购费${this.responseObj.totalAmount}，帐户实时可支配余额为${this.responseObj.disposableAmount}。</div>
              <div>${text}</div>
            </div>
          `
          return str
        }
        case 'taskApprover': {
          let text = ''
          if (
            this.responseObj.totalAmount > this.responseObj.disposableAmount
          ) {
            text = '您的帐户余额不足，暂不支持此次批量分配'
          } else {
            text = '您的帐户余额充足，若无异议，请点击“开始分配”'
          }
          const str = `
            <div>
              <div>本次将支付积分${this.responseObj.totalAmount}，帐户实时可支配余额为${this.responseObj.disposableAmount}。</div>
              <div>${text}</div>
            </div>
          `
          return str
        }
        default:
          return '若无异议，请点击“开始导入”'
      }
    }
  },
  methods: {
    ...mapActions([
      'batchImportOrgModel',
      'uploadBatchImportOrg',
      'uploadBatchImportOrgConfirm',
      'batchImportRepresentModel',
      'uploadBatchImportRepresent',
      'uploadBatchImportRepresentConfirm',
      'getAttributeRepresentFile',
      'getAttributeDoctorFile',
      'getExcelResultData',
      'batchImportKnowledgePointModelFile',
      'downBatchPayLiveFee',
      'confirmBatchPayLiveFee',
      'downBatchSceneTaskAudit',
      'confirmBatchSceneTaskAudit',
      'importCommonDownModel',
      'confirmBatchImportOrgGradeSpeakerModel'
    ]),
    // ---------- 工具 ------------------
    onDownFunction(fun) {
      // 下载模板
      const params = this.initModelType[this.modelType].reqObj || {}
      fun &&
        fun(params).then(
          res => {
            if (this.initModelType[this.modelType].isGoDownCenter) {
              this.$message.success(res.data)
            } else {
              window.open(res.data)
            }
          },
          rea => {
            this.$message({
              message: rea.message,
              type: 'error'
            })
          }
        )
    },
    onUploadConfirm(fun, data) {
      //  导入表格确认
      if (this.loading) return
      this.loading = true
      fun &&
        fun(data).then(
          res => {
            this.loading = false
            this.isImport = true
            this.$emit('onComplete', res)
          },
          rea => {
            this.loading = false
            this.$message({
              message: rea.message,
              type: 'error'
            })
          }
        )
    },
    // ----------------------------------
    onJumpHelp() {
      // 跳转帮助中心
      let url = ''
      if (this.initModelType[this.modelType]?.url) {
        url = this.$router.resolve(this.initModelType[this.modelType]?.url)
      }
      window.open(url.href, '_blank')
    },
    onUploadProgress(event, file) {
      // 文件本地上传进度
      this.uploadFlag = true
      this.$nextTick(() => {
        this.uploadProgress = file.percentage.toFixed(0) * 1
      })
    },
    onUploadSuccess(response, file) {
      // 校验结果
      if (response.data) {
        this.isSuccess = true
        this.uploadFlag = false
        this.isCheck = true
        this.responseObj = response.data
        this.isCheckPass = !(response.data.errorCount > 0)
        this.file = file.raw
      } else {
        this.$message({
          message: response.message,
          type: 'error'
        })
        this.onUploadError()
      }
    },
    onUploadError() {
      // 校验错误显示
      this.isSuccess = false
      this.uploadFlag = false
      this.isCheckPass = false
    },
    onDownModal() {
      // 下载模板
      const fn = this.initModelType[this.modelType]?.downFn
      fn && this.onDownFunction(fn)
    },
    onDownError() {
      // 下载失败模板
      if (this.responseObj.errorExcelUrl) {
        window.open(this.responseObj.errorExcelUrl)
      }
    },
    onBackSteps() {
      // 重新上传
      this.isUpload = true
      this.isImport = false
      this.isSuccess = false
      this.uploadFlag = true
      this.isCheck = false
      this.responseObj = {}
      this.isCheckPass = false
      this.file = null
      this.loading = false
      this.uploadProgress = 0
    },
    onConfirm() {
      // 导入表格确认
      if (!this.responseObj?.successCount) {
        this.onCloseModel()
        return this.$message.error('请按模板填写有效数据')
      }
      const fn = this.initModelType[this.modelType]?.confirmFn
      let data = null
      if (['addOrg', 'addStaff'].includes(this.modelType)) {
        data = this.file
      } else {
        data = {
          dataId: this.responseObj.dataId || ''
        }
      }
      fn && this.onUploadConfirm(fn, data)
    },
    onCloseModel() {
      // 完成
      this.dialogFormVisible = false
    }
  }
}
</script>
<style lang="scss">
.upload-model-box .el-upload--picture-card {
  width: 160px;
  height: 90px;
}
.upload-btn-box .el-upload--picture-card {
  width: auto;
  height: auto;
  line-height: inherit;
  border: none;
}
</style>
<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center {
  text-align: center;
}
.steps-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.out-line-left {
  border-radius: 100px 0 0 100px;
}
.out-line-left,
.out-line-center,
.out-line-right {
  width: 140px;
  height: 10px;
  background-color: #d8d8d8;
  margin: 0 5px;
  @media screen and (max-width: 768px) {
    width: 80px;
  }
}
.out-line-right {
  border-radius: 0 100px 100px 0;
}
.is-already {
  background-color: #3d61e3;
}
.is-already-text {
  color: #3d61e3;
}
.upload-content-box {
  padding: 50px;
  width: fit-content;
  margin: auto;
  color: #5a5a5a;
}
.remark {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #909399;
  margin-top: 20px;
}
.upload-model-box {
  display: flex;
  padding-top: 16px;
  flex-wrap: wrap;
}
.upload-cover {
  width: 100%;
  height: 100%;
  background-color: #e1e1e1;
  color: #787878;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  img {
    width: 30px;
  }
  span {
    height: 30px;
    line-height: 30px;
  }
}
.out-progress {
  width: 160px;
  height: 6px;
  margin-top: 10px;
  background: #e1e1e1;
  border-radius: 20px;
  overflow: hidden;
}
.in-progress {
  height: 100%;
  background: #30c691;
  border-radius: 20px;
}
.check-info-box {
  padding: 60px 100px;
  color: #0f0f0f;
  text-align: center;
  @media screen and (max-width: 768px) {
    padding: 60px 20px;
  }
}
.vHtml {
  color: #0f0f0f;
  text-align: center;
  margin-top: 10px;
}
.import-info {
  text-align: center;
  padding: 50px;
}
.pdg-t10 {
  padding-top: 10px;
}
.mgn-r20 {
  margin-right: 20px;
}
</style>
