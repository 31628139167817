<template>
  <page-main back title="客户管理 - 外部客户管理">
    <el-form inline class="customize-search-form search-box">
      <el-form-item>
        <el-input
          v-model="searchParams.doctorName"
          class="input"
          clearable
          placeholder="客户姓名"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="searchParams.mobile"
          class="input"
          clearable
          placeholder="客户手机号"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="searchParams.institution"
          class="input"
          clearable
          placeholder="客户所属机构"
        />
      </el-form-item>

      <el-form-item label="录入时间">
        <el-date-picker
          v-model="searchParams.date"
          class="create-date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          value-format="yyyy-MM-dd HH:mm:ss"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          style="min-width: 90px; margin-right: 10px;"
          @click="onSearch();resetSelection()"
        >
          查询
        </el-button>
        <el-dropdown trigger="click" @command="onAddClientCommand">
          <el-button type="primary">新建客户<i class="el-icon-arrow-down el-icon--right" /></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="single">新增单个医生</el-dropdown-item>
            <el-dropdown-item command="batch">批量新增客户</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-form-item>
    </el-form>
    <div class="batch-box">
      <div class="left">
        <span class="total-count"> 共 {{ pagination.total }} 条数据，已选 {{ multipleSelection.length }} 条 </span>
      </div>
      <div class="right">
        <template>
          <el-button plain type="info" @click="batchOperate">
            批量删除
          </el-button>
        </template>
        <el-popover
          v-model="showExportPop"
          width="200"
          trigger="manual"
          placement="left"
        >
          <div>是否导出数据?</div>
          <div style="text-align: right; margin: 0;">
            <el-button size="mini" type="text" @click="showExportPop = false">取消</el-button>
            <el-button type="primary" size="mini" @click="onExport">确定</el-button>
          </div>
          <el-button
            slot="reference"
            :underline="false"
            type="text"
            class="btn-link"
            style="margin-left: 10px;"
            @click="onShowExportModal"
          >
            导出数据
          </el-button>
        </el-popover>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="doctorList"
      stripe
      :row-key="(row) => row.id"
      class="customize-el-table table"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        :reserve-selection="true"
        type="selection"
        :selectable="(e) => !(e.isInBindBlackList === 1 || e.isCancel === 1 || e.isFrozen === 1)"
        width="50"
      />
      <el-table-column
        prop="doctorName"
        label="姓名"
        fixed="left"
        width="120"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.doctorName || '/' }}</span>
          <span v-if="scope.row.isCancel" style="color: #ff4921; padding-left: 3px;">(已注销)</span>
          <span v-if="scope.row.isFrozen" style="color: #fb9c35; padding-left: 3px;">(已冻结)</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="所属职称">
        <template slot-scope="scope">
          <span>{{ scope.row.titleName || '/' }}</span>
        </template>
      </el-table-column>

      <el-table-column
        prop="institution"
        align="center"
        show-overflow-tooltip
        label="医院"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.institution || '/' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="selectedDepartmentId" label="所在科室" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.department || '/' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="titleName"
        align="center"
        show-overflow-tooltip
        label="职业类型"
      >
        <template slot-scope="scope">
          <span>{{ titleNameFormat(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="institutionArea"
        align="center"
        show-overflow-tooltip
        label="省市"
      />
      <el-table-column
        prop="mobile"
        align="center"
        show-overflow-tooltip
        label="手机号"
        width="120px"
      >
        <!-- 如果是趣学术医生，则做脱密处理 -->
        <template slot-scope="scope">
          <!-- 手机号脱敏,把手机号，中间几位替换成*号 -->
          <span v-if="scope.row.userSource === 1">{{ scope.row.mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2') || '/' }}</span>
          <span v-else>{{ scope.row.mobile || '/' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="doctorCode"
        align="center"
        width="130px"
        label="编码"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.doctorCode || '/' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="importDate"
        align="center"
        width="140px"
        label="录入时间"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.importDate || '/' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        align="center"
        width="180"
        label="操作"
      >
        <template slot-scope="{row}">
          <el-button type="text" style="color: #ff4921;" @click="onDelDoctor([row.id])">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        :small="pagination.small"
        style="margin: auto;"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
    </div>
    <SingleAddDoctorDialog
      v-model="singleAddDoctorVisible"
      :editor-doctor-info="editorDoctorInfo"
      :job-profession-list="jobProfessionList"
      @closed="editorDoctorInfo = {}"
      @onConfirm="getDataList"
    />
    <CommonImportExcelDialog
      v-model="batchAddDoctorVisible"
      title="批量新建客户"
      :upload-api="$API.batchImportOrgDoctorModelFile"
      :down-fn="onDownFile"
      :confirm-api="$API.batchImportDoctorInfo"
      @onComplete="onComplete"
    />
  </page-main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import paginationMixin from '@/mixins/pagination'
import SingleAddDoctorDialog from './components/SingleAddDoctorDialog.vue'
import CommonImportExcelDialog from '@/baseComponents/CommonImportExcelDialog.vue'
import {cloneDeep} from 'lodash-es'

export default {
  name: 'ImportDoctorList',
  components: {
    SingleAddDoctorDialog,
    CommonImportExcelDialog
  },
  filters: {
    payTipsFilter(record) {
      return `确认支付${record.doctor.name}视频采购费${record.fee}元`
    }
  },
  mixins: [paginationMixin],
  data() {
    return {
      selectedDoctorId: '', // 选择的医生id
      selectedDepartmentId: '',
      showExportPop: false,
      loading: false,
      form: {},
      content: '',
      doctorIds: [],
      repCount: 0,
      multipleSelection: [],
      showBind: false,
      bindLoading: false,
      searchParams: {
        date: [],
        doctorName: '',
        mobile: '',
        institution: ''
      },
      doctorList: [],
      singleAddDoctorVisible: false,
      batchAddDoctorVisible: false,
      jobProfessionList: [],
      hospitalLevel: [],
      provinceList: [],
      editorDoctorInfo: {},
      platformDepartmentList: []
    }
  },
  computed: {
    ...mapGetters(['certifyStatusList', 'userInfo', 'bindStatusList'])
  },
  mounted() {
    this.getDataList()
    if (this.platformDepartmentList.length < 2) {
      this.getPlatformDepartmentList()
    }
    this.getHospitalLevel()
    // 职业类型
    this.getJobProfessionList()

    this.getProvinceList()
  },
  methods: {
    ...mapActions(['getRepresentOrgList']),
    onDownFile() {
      window.open('https://cdnfile.yaomaitong.net/excel/%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E5%8C%BB%E7%94%9F%E6%A8%A1%E6%9D%BF%20.xlsx')
    },
    onComplete() {
      this.getDataList()
    },
    onDelDoctor(ids) {
      console.log(ids)
      this.$confirm('请确认是否删除客户，一经删除不可恢复。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$API.deleteImportDoctorList, ids).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getDataList()
        }, err => {
          this.$message({
            type: 'error',
            message: err.message
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    titleNameFormat(row) {
      return this.jobProfessionList.find(item => item.id === row.professionType)?.label || '/'
    },
    // 获取平台科室
    getPlatformDepartmentList() {
      this.$axios.get(this.$API.platformDepartment).then(
        res => {
          this.platformDepartmentList = res.data
        },
        rea => {
          this.$message.error(rea.message)
        }
      )
    },
    onExport() {
      if (!this.doctorList.length) return this.$message.error('暂无需要导出的数据')
      const params = this.formatParams({
        pageNo: 1,
        pageSize: 99999999,
        ...this.searchParams
      })
      this.$axios.post(this.$API.exportImportDoctorList, params).then(
        res => {
          this.$message.success(res.data)
          this.showExportPop = false
        },
        rea => {
          this.$message.error(rea.message)
          this.showExportPop = false
        }
      )
    },
    onShowExportModal() {
      this.showExportPop = true
    },
    onClose() {
      this.showBind = false
    },
    // 新增客户
    onAddClientCommand(command) {
      switch (command) {
        case 'batch':
          this.batchAddDoctorVisible = true
          break
        case 'single':
          this.singleAddDoctorVisible = true
          break
        default:
          break
      }
    },
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    },
    formatParams(arg) {
      const params = cloneDeep(arg)
      if (params.date) {
        params.date[0] && (params.startAt = params.date[0])
        params.date[1] && (params.endAt = params.date[1])
      }
      delete params.date
      return params
    },
    async getDataList() {
      this.resetSelection()
      this.loading = true
      const params = this.formatParams(this.getParams(this.searchParams))
      try {
        const res = await this.$axios.post(this.$API.getImportDoctorList, params)
        this.doctorList = res.data?.data || []
        this.multipleSelection.map(row => {
          this.$refs.multipleTable.toggleRowSelection(row, true)
        })
        this.pagination.total = res.data?.totalCount || 0
        this.loading = false
      } catch (error) {
        this.doctorList = []
        this.loading = false
        this.$message.error(error.message)
      }
    },
    // 获取医生职业接口
    getJobProfessionList() {
      this.$axios.get(this.$API.jobTitles).then(
        res => {
          this.jobProfessionList = res.data?.filter(item => item.id !== 2) || []
        },
        rea => {
          this.$message.error(rea.message)
        }
      )
    },
    // 获取医院等级接口
    getHospitalLevel() {
      this.$axios.get(this.$API.hospitalLevelList).then(
        res => {
          this.hospitalLevel = res.data
        },
        rea => {
          this.$message.error(rea.message)
        }
      )
    },
    // 获取省市区接口
    async getProvinceList() {
      try {
        const {data} = await this.$axios.get(this.$API.getProvince)
        this.provinceList = data.map(item => {
          item.value = item.provinceId
          item.label = item.provinceName
          if (item.cities.length) {
            item.children = item.cities.map(city => {
              city.value = city.cityId
              city.label = city.cityName
              return city
            })
          }
          return item
        })
      } catch (error) {
        console.log('error: ', error)
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    batchOperate() {
      if (!this.multipleSelection.length) {
        return this.$message.error('请选择要操作的医生')
      }
      const ids = this.multipleSelection.map(item => item.id)
      this.onDelDoctor(ids)
    },
    resetSelection() {
      this.multipleSelection = []
      this.$refs.multipleTable.clearSelection()
    }
  }
}
</script>
<style lang="scss" scoped>
.search-box {
  .input {
    width: 130px;
    ::v-deep .el-input__inner {
      border-radius: 30px;
      background: #fafafa;
    }
  }
  .select-small {
    width: 140px;
  }
  .create-date {
    background-color: #fbfbfb;
    ::v-deep .el-range-input {
      background-color: #fbfbfb;
    }
  }
  .el-date-editor {
    .el-icon-date {
      order: -10;
    }
  }
}
.batch-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 5px;
  .total-count {
    color: #969696;
  }
}
.status-img {
  margin-right: 5px;
}
.table-footer {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  .risk-check {
    color: #969696;
  }
}
::v-deep .btn-link {
  span {
    // 添加下划线
    text-decoration: underline;
  }
}
</style>
