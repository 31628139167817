<template>
    <el-dialog class="add-staff-box" title="变更组织" :visible.sync="showChangeOrgDialog" :show-close="false">
        <div class="mgn-l50">
            <div>变更组织：
                <el-cascader
                    :options="orgList"
                    :props="defaultProps"
                    :show-all-levels="false"
                    placeholder="请选择所属组织"
                    @change="handleChange"
                    class="el-select"
                ></el-cascader>
            </div>
            <div class="mgn-b20">医生迁移：
                <el-radio v-model="radio" label="0">迁移至新组织，且原组织保留</el-radio>
                <div class="radio">
                    <el-radio v-model="radio" label="1">不迁移至新组织</el-radio>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="onClose">取消变更</el-button>
            <el-button type="primary" @click="onChangeOrg" :loading="loading">确认并返回</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'ChangeOrgDialog',
    props: {
        showChangeOrgDialog: {
            type: Boolean,
            default: false
        },
        representId: {
            type: String,
            default: ''
        },
        fromOgId: {
            type: String,
            default: ''
        },
        orgList: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            radio: '0',
            defaultProps: {
                children: 'child',
                label: 'orgName',
                value: 'orgId',
                disabled: 'isSelect',
                // disabled: function (data, node) {
                //     if (node.data.isSelect || !node.data.status) {
                //         return true
                //     } else {
                //         return false
                //     }
                // },
                checkStrictly: true
            },
            currentOrgList: [],
            targetOrgId: '',
            loading: false,
        }
    },
    computed: {
        // ...mapGetters(['orgList'])
    },
    methods: {
        ...mapActions(['changeOrganization', 'getOrgList']),
        handleChange(value) {
            this.currentOrgList = value
        },
        onChangeOrg() {
            this.targetOrgId = this.currentOrgList[this.currentOrgList.length - 1]
            if (!this.targetOrgId) {
                return this.$message.error('请选择所属组织')
            }
            this.loading = true
            this.changeOrganization({
                representId: this.representId,
                fromOgId: this.fromOgId,
                targetOrgId: this.targetOrgId,
                migrationRule: this.radio
            }).then(res => {
                this.loading = false
                this.$message.success({message:'变更成功',type:'success',duration:1000})
                setTimeout(() => {
                    this.$emit('onChange', this.targetOrgId, res.data.orgName)
                }, 1000)
            }, rea => {
                this.loading = false
                this.$message.error(rea.message)
            })
        },
        onClose() {
            this.$emit('onCloseChange')
        }
    },
    mounted() {
    }
}

</script>

<style lang="scss" scoped>
.add-staff-box {
    ::v-deep .el-dialog .el-dialog__header {
        background-color: #ffffff;
        text-align: left;
        border-bottom: 1px solid #e1e1e1;

        .el-dialog__title {
            color: #0F0F0F;
            font-size: 14px;
        }

        .el-dialog__close {
            color: #909399 !important;
        }
    }

    ::v-deep.el-dialog {
        width: 35% !important
    }

    ::v-deep .el-form-item__content {
        width: 60%;
    }

    ::v-deep .el-input__inner {
        background-color: #ffffff !important;
        border: none;
    }
}

.el-input {
    border-bottom: 1px solid #e1e1e1;
}

.el-select {
    border: 1px solid #e1e1e1;
    display: inline-block;
    width: 220px;
    margin-bottom: 30px;
}

.dialog-footer {
    text-align: center;
}

.change-org {
    color: #3D61E3;
    margin-left: 10px;
    cursor: pointer;
}

.radio {
    margin: 20px 0 60px 74px;
}
</style>

