<template>
  <div class="freeze-dialog-box">
    <el-dialog title="温馨提示" :visible.sync="dialogFormVisible" :show-close="false">
      <div class="freeze-info left-align">
        <div class="bold">
            <img src="@/assets/iconImg/warn.png" style="width: 14px;margin-right: 3px;" />
            <span>确定将<span class="name-text">{{name}}</span>变更为<span class="name-text">{{toName}}</span>的下级组织吗？</span>
        </div>
        <div style="color: #5A5A5A;">移动后会造成以下影响，请谨慎操作：</div>
        <div style="color: #5A5A5A;">1.员工、组织资金及绑定医生等相关数据将会同步变动</div>
        <div style="color: #5A5A5A;">2.组织中正在执行的任务将自动撤回</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button type="primary" :disabled="disabled" @click="submitForm">确定 <span v-show="time > 0">{{time}}s</span> </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: "RemoveOrg",
  props: {
    isShowPop: {
      type: Boolean,
      default: false
    },
    formObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isFreeze: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    dialogFormVisible: {
      get() {
        this.open = this.isShowPop
        return this.isShowPop
      },
      set(val) {
        this.open = val
      }
    },
    name() {
      return this.formObj.name || this.formObj.draggingNode?.data?.orgName
    },
    toName() {
      return this.formObj.toName || (this.formObj.dropType === 'inner' ? this.formObj.dropNode?.data?.orgName : this.formObj.dropNode?.parent?.data?.orgName)
    },
    disabled() {
        this.time <= 0 && clearInterval(this.timerNum)
        return this.time > 0;
    }
  },
  data() {
    return {
      open: false,
      title: '冻结',
      time: 15,
      timerNum: null,
    }
  },
  methods: {
    ...mapActions(['onCreateDepartment','getOrgList']),
    onClose(isGet = true) {
      this.dialogFormVisible = false
      this.$emit('onClose',{isGet,close:false})
    },
    submitForm(type) {
      this.onClose(false)
      this.$emit('onRemoveOrgClick')
    },
  },
  mounted() {
      this.time = 15
      this.timerNum = setInterval(() => {
          --this.time
      },1000)
  },
  beforeDestroy() {
      clearInterval(this.timerNum)
  }
}
</script>

<style lang="scss" scoped>
.freeze-dialog-box {
  font-size: 14px;
  ::v-deep .el-dialog {
        width: fit-content;
        min-width: 460px;
    }
  ::v-deep .el-dialog .el-dialog__header {
    background-color: #ffffff;
    text-align: left;
    border-bottom: 1px solid #e1e1e1;
    .el-dialog__title {
      color: #0F0F0F;
      font-size: 14px;
    }
    .el-dialog__close {
      color: #909399 !important;
    }
  }
}
.freeze-info {
  width: fit-content;
  margin: auto;
  font-weight: bold;
  font-size: 14px;
  padding: 36px 0 30px;
  color: #0F0F0F;
  text-align: left;
  line-height: 22px;
}
.left-align {
    text-align: left;
    width: fit-content;
    margin: auto;
}
.bold {
    font-weight: bold;
    display: flex;
    align-items: center;
}
.dialog-footer {
  text-align: center;
}
.name-text {
  color: #3D61E3;
}

.center {
  text-align: center;
}
</style>
