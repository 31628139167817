<template>
  <div class="delete-dialog-box">
    <el-dialog  title="删除员工" :visible.sync="dialogFormVisible">
      <div class="freeze-info left-align">
        <div class="bold">
            <img src="@/assets/iconImg/fail.png" style="width: 14px;margin-right: 3px;" />
            <span>确定要将{{formObj.representName}}从{{formObj.orgName}}删除？</span>
        </div>
        <div style="color: #5A5A5A;">删除后，员工将无法使用趣科会登录该组织</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">删除</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: "DelRepresentTip",
  props: {
    isShowPop: {
      type: Boolean,
      default: false
    },
    formObj: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    dialogFormVisible: {
      get() {
        this.open = this.isShowPop
        this.isShowTip = false
        return this.isShowPop
      },
      set(val) {
        this.open = val
        this.$emit('onClose',val)
      }
    }
  },
  data() {
    return {
      open: false,
      title: '删除',
      isShowTip: false,
      message: '',
      loading: false,
    }
  },
  methods: {
    ...mapActions(['delOrgRepresent']),
    onDelOrg() {
        if(this.loading) return
        this.loading = true
        this.delOrgRepresent({targetOrgId:this.formObj.orgId,representId:this.formObj.representId}).then(res => {
            this.dialogFormVisible = false
            this.$message.success("删除操作成功")
            this.loading = false
            this.$emit('onConfirm')
        },rea => {
            this.$message.error(rea.message)
            this.loading = false
        })
    },
    submitForm() {
      this.onDelOrg()
    },
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.delete-dialog-box {
    ::v-deep .el-dialog {
        width: fit-content;
        min-width: 460px;
    }
  ::v-deep .el-dialog .el-dialog__header {
    background-color: #ffffff;
    text-align: left;
    border-bottom: 1px solid #e1e1e1;
    .el-dialog__title {
      color: #0F0F0F;
      font-size: 14px;
    }
    .el-dialog__close {
      color: #909399 !important;
    }
  }
}
.freeze-info {
  font-weight: bold;
  font-size: 14px;
  padding: 36px 0 30px;
  color: #0F0F0F;
  line-height: 22px;
}
.left-align {
    text-align: left;
    width: fit-content;
    margin: auto;
}
.bold {
    font-weight: bold;
    display: flex;
    align-items: center;
}
.dialog-footer {
  text-align: center;
}
</style>
