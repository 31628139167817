<template>
  <div>
      <el-dialog v-show="!isShowTip&&!isShowImport" class="add-orginazation-box" :title="type === 'add'?'新建组织':'编辑组织'" :visible.sync="dialogFormVisible">
        <el-form :model="form" :rules="rules" ref="ruleForm" :validate-on-rule-change="false">
          <el-form-item label="组织名称" prop="orgName" :label-width="formLabelWidth">
            <el-input v-model="form.orgName" autocomplete="off" placeholder="必填，请输入组织名称"></el-input>
          </el-form-item>
          <el-form-item label="上级组织" prop="parentOrgId" :label-width="formLabelWidth">
            <el-cascader ref="cascader" v-model="form.parentOrgId" :options="orgList" :props="{label:'orgName',value:'orgId',children:'child',checkStrictly: true}" :show-all-levels="true" @change="onChangOrg"></el-cascader>
          </el-form-item>
          <el-form-item label="组织管理员" prop="adminName" :label-width="formLabelWidth">
            <el-input v-model="form.adminName" autocomplete="off" placeholder="选填，请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="管理员手机" prop="adminMobile" :label-width="formLabelWidth">
            <el-input v-model="form.adminMobile" type="tel" :maxlength="11" autocomplete="off" placeholder="选填，请输入管理员手机号"></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align:center;">
          备注：如有大量组织需要创建，可以试试<el-button type="text" @click="onImportOrg">批量导入组织</el-button>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </div>
      </el-dialog>
      <RemoveOrg v-if="isShowTip" :formObj="removeRestObj" :isShowPop="isShowTip" @onRemoveOrgClick="onRemoveOrgClick" @onClose="onCloseRemoveOrg" />
      <ImportExcelDialog v-if="isShowImport" :isShowPop="isShowImport" :modelType="modelType" @onComplete="onComplete" @onConfirm="dialogFormVisible = false" @onClose="(val) => {isShowImport = val}" />
  </div>
</template>

<script>
import RemoveOrg from './RemoveOrg'
import ImportExcelDialog from '@/baseComponents/ImportExcelDialog'
import { checkPassWordStrength,CtoH } from "@/utils/util";

import { mapActions, mapGetters } from 'vuex'
export default {
  name: "Addorginazation",
  components: {
    RemoveOrg,
    ImportExcelDialog
  },
  props: {
    isShowPop: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'add'
    },
    formObj: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    dialogFormVisible: {
      get() {
        this.open = this.isShowPop
        return this.isShowPop
      },
      set(val) {
        this.open = val
        if(!val) {
          this.$refs['ruleForm'].resetFields();
        }
        this.$emit('onClose',val)
      }
    },
    rules() {
      if(this.form.adminName || this.form.adminMobile) {
        return {
          orgName: [
            { required: true, message: '请输入组织名称', trigger: 'blur' },
          ],
          parentOrgId: [
            { required: true, message: '请选择上级组织', trigger: 'blur' },
          ],
          adminName: [
            { required: true, message: '请输入管理员姓名', trigger: 'blur' },
          ],
          adminMobile: [
            { required: true, message: '请输入管理员手机号', trigger: 'blur' },
          ]
        }
      } else {
        return {
          orgName: [
            { required: true, message: '请输入组织名称', trigger: 'blur' },
          ],
          parentOrgId: [
            { required: true, message: '请选择上级组织', trigger: 'blur' },
          ]
        }
      }
    },
    parentOrgId() {
      return typeof this.form.parentOrgId === 'string' ? this.form.parentOrgId : this.form.parentOrgId[this.form.parentOrgId.length - 1]
    },

  },
  data() {
    return {
      baseParentId: '',
      baseParentName: '',
      removeRestObj: {},
      open: false,

      loading: false,

      orgList: [],

      form: {
        orgId: '',
        orgName: '',
        parentOrgId: '',
        parentOrgName: '',
        adminName: '',
        adminMobile: '',
      },
      isShowTip: false,
      formLabelWidth: '120px',

      isShowImport: false,
      modelType:'addOrg'

    }
  },
  methods: {
    ...mapActions(['onCreateDepartment','getOrgList','getOrgInfoList','editOrgInfo','conditionTreeList']),
    // ----------------------------- 工具 ------------------

    isvalidPhone (str) {
        const reg = /^1[0-9]\d{9}$/
        return reg.test(str)
    },
    // -----------------------------------------------------
    onComplete() {
      this.getOrgList()
    },
    onImportOrg() {
      this.isShowImport = true
    },
    onGetFilterList() {
      this.conditionTreeList({orgId:this.form.orgId}).then(res => {
        this.orgList = [res.data]
      })
    },
    onCloseRemoveOrg(options) {
      this.isShowTip = false;
    },
    onChangOrg(options) {
      console.log(options)
    },
    onRemoveOrgClick() {
        if(this.form.adminName && !this.isvalidPhone(this.form.adminMobile)) {
            this.form.adminMobile = this.form.adminMobile.trim()
            this.$message.error("请填写正确手机号")
            return
        }
        this.$set(this.form,'adminName',CtoH(this.form.adminName || '').trim())
      this.editOrgInfo({
        orgName: this.form.orgName.trim(),
        orgId: this.form.orgId,
        parentOrgId: this.parentOrgId,
        managerName: this.form.adminName,
        mobile: this.form.adminMobile,
        accountType: 1
      }).then(res => {
        this.loading = false
        this.$message({
          message:'编辑成功',
          type:"success"
        })
        this.$emit("onEditConfirm",{orgName:this.form.orgName.trim(),orgId:this.form.orgId})
        this.getOrgList()
        this.dialogFormVisible = false
      },rea => {
        this.loading = false
        this.$message({
          message:rea.message,
          type:"error"
        })
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
            if(this.form.adminName && !this.isvalidPhone(this.form.adminMobile)) {
                this.form.adminMobile = this.form.adminMobile.trim()
                this.$message.error("请填写正确手机号")
                return
            }
            this.$set(this.form,'adminName',CtoH(this.form.adminName || '').trim())
          if(this.loading) return
          this.loading = true
          if(this.type === 'add') {
            this.onCreateDepartment({
              orgName: this.form.orgName.trim(),
              parentOrgId: this.parentOrgId,
              adminName: this.form.adminName,
              adminMobile: this.form.adminMobile,
              accountType: 1
            }).then(res => {
              this.loading = false
              this.$message({
                message:'添加成功',
                type:"success"
              })
              this.getOrgList()
              this.dialogFormVisible = false
            },rea => {
              this.loading = false

              this.$message({
                message:rea.message,
                type:"error"
              })
            })
          } else {
            console.log(this.baseParentId, this.parentOrgId)
            if(this.baseParentId !== this.parentOrgId) {
              this.isShowTip = true
              this.removeRestObj = {name: this.form.orgName,toName: this.$refs.cascader?.getCheckedNodes()[0]?.label}
              this.loading = false
              return
            }
            this.onRemoveOrgClick()
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    onGetInfo() {
      this.getOrgInfoList({orgId:this.form.orgId}).then(res => {
        let {data = {}} = res
        this.form = Object.assign({},this.form,data,{adminName:res.data?.managerName,adminMobile:res.data?.managerMobile})
        this.baseParentId = data.parentOrgId
      })
    }
  },
  mounted() {
    this.form = Object.assign({},this.form,this.formObj)
    this.onGetFilterList()
    if(this.form.orgId) {
      this.onGetInfo()
    }

  }
}
</script>

<style lang="scss" scoped>
.add-orginazation-box {
  ::v-deep .el-dialog .el-dialog__header {
    background-color: #ffffff;
    text-align: left;
    border-bottom: 1px solid #e1e1e1;
    .el-dialog__title {
      color: #0F0F0F;
      font-size: 14px;
    }
    .el-dialog__close {
      color: #909399 !important;
    }
  }
  ::v-deep .el-form-item__content {
    width: 70%;
    border-bottom: 1px solid #e1e1e1;
  }
  ::v-deep .el-input__inner {
    background-color: #ffffff !important;
    border: none;
  }
  ::v-deep .el-cascader{
    width: 100%;
  }
}
.dialog-footer {
  text-align: center;
}
</style>
