<template>
  <el-dialog
    :title="`${editorDoctorInfo.id ? '编辑' : '新建'}客户`"
    v-bind="$attrs"
    :visible.sync="visible"
    width="595px"
    class="customize-el-dialog single-add-doctor"
    v-on="$listeners"
    @open="handleOpen"
    @close="handleClose"
  >
    <el-form
      ref="form"
      :rules="rulesForm"
      :model="form"
      label-position="left"
      inline
      class="customer-form"
    >
      <el-form-item label="客户基础信息" required class="customer-info">
        <el-form-item prop="name">
          <el-input
            v-model.trim="form.name"
            :max="20"
            :disabled="disableChange"
            class="input"
            placeholder="请输入客户姓名"
            clearable
          />
        </el-form-item>
        <el-form-item prop="mobile">
          <el-input
            v-model.trim="form.mobile"
            v-debounce="[queryCustomerByMobile, 'input']"
            :disabled="disableChange"
            :maxlength="11"
            class="input"
            placeholder="请输入手机号"
            clearable
            :suffix-icon="isLoadCustomerInfoLoading?'el-icon-loading':''"
          />
        </el-form-item>
        <el-form-item prop="doctorCode">
          <el-input
            v-model.trim="form.doctorCode"
            class="input"
            :maxlength="100"
            placeholder="请输入医生编码"
            clearable
          />
        </el-form-item>
        <el-form-item prop="titleId">
          <el-cascader
            :disabled="disableChange"
            :value="[form.professionType, form.titleId]"
            :options="jobProfessionList"
            :props="{value:'id', children: 'childrenList'}"
            clearable
            placeholder="请选择职业/职称"
            @input="(e) => {form.professionType = e[0]; form.titleId = e[1]}"
          />
        </el-form-item>
        <el-form-item prop="hospitalInfo">
          <el-select
            v-model="form.hospitalInfo"
            :disabled="disableChange"
            class="select"
            :placeholder="`请输入${form.professionType === 2?'药店':'医院' }名称查询${form.professionType === 2?'药店':'医院' }`"
            clearable
            filterable
            remote
            allow-create
            value-key="id"
            :remote-method="getHospitalList"
            :loading="hospitalLoading"
          >
            <el-option
              v-for="item in hospitalList"
              :key="item.id"
              :value="item"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.professionType !== 2" prop="oneTwoLevelDepartment">
          <el-cascader
            v-model="form.oneTwoLevelDepartment"
            :disabled="disableChange"
            :loading="hospitalDepartmentLoading"
            :options="hospitalDepartmentList"
            :props="{checkStrictly: true, value:'id', label:'label', children: 'childes'}"
            collapse-tags
            collapse-tags-tooltip
            clearable
            placeholder="请选择一级/二级科室"
          />
        </el-form-item>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="onSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { cloneDeep } from 'lodash-es'
// const mobileReg = /^1[3456789]\d{9}$/
// 限制只能输入 11位数字
const mobileReg = /^\d{11}$/

export default {
  name: 'SingleAddDoctor',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    jobProfessionList: {
      type: Array,
      default: () => []
    },
    editorDoctorInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        id: '',
        doctorCode: '',
        name: '',
        mobile: '',
        professionType: '',
        titleId: '',
        oneLevelDepartmentId: '',
        twoLevelDepartmentId: '',
        hospitalInfo: null
      },
      rulesForm: {
        mobile: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {pattern: mobileReg, message: '请输入正确的手机号', trigger: 'blur'}
        ],
        name: [
          {required: true, message: '请输入客户姓名', trigger: 'blur'}
        ],
        professionType: [
          {required: true, message: '请选择职业', trigger: 'change'}
        ],
        titleId: [
          {required: true, message: '请选择职称', trigger: 'change'}
        ],
        hospitalInfo: [
          {required: true, message: '请选择', trigger: 'change'}
        ],
        oneTwoLevelDepartment: [
          {required: false, message: '请选择科室', trigger: 'change'}
        ]
      },
      isLoadCustomerInfoLoading: false,
      orgChildList: [],
      orgRepLoading: true,
      hospitalLoading: false,
      hospitalList: [],
      hospitalDepartmentLoading: false,
      hospitalDepartmentList: [],
      byMobileDetail: {}
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    disableChange() {
      return [ 1, 2, 4 ].includes(this.form.certifyStatus)
    }
  },
  watch: {
    'form.hospitalInfo'(val) {
      if (val) {
        this.form.oneLevelDepartmentId = ''
        this.form.twoLevelDepartmentId = ''
      }
    }
  },
  mounted() {
    this.getOrgChildList()
    this.getHospitalDepartmentList()
  },
  methods: {
    async handleOpen() {
      if (this.editorDoctorInfo.id) {
        await this.getHospitalList(this.editorDoctorInfo.hospital)
        this.mergeToForm(this.editorDoctorInfo)
      }
    },
    handleClose() {
      this.form = this.$options.data().form
      this.$refs.form.resetFields()
    },
    mergeToForm(res) {
      this.form = {
        ...this.form,
        doctorCode: res.code || res.orgDoctorCode,
        id: res.id,
        name: res.name,
        mobile: res.mobile,
        certifyStatus: res.certifyStatus,
        professionType: res.identity || res.professionType,
        titleId: res.titleId,
        hospitalInfo: {
          id: res.hospitalId,
          name: res.hospital
        },
        oneTwoLevelDepartment: [res.oneLevelDepartmentId, res.twoLevelDepartmentId]
      }
    },
    // 根据手机号查询客户
    async queryCustomerByMobile() {
      // 使用正则校验手机号
      if (!mobileReg.test(this.form.mobile)) {
        return
      }

      try {
        this.isLoadCustomerInfoLoading = true
        const {data} = await this.$axios.get(this.$API.baseInfoByMobile, {
          params: {
            mobile: this.form.mobile
          }
        })
        if (data.hospital) {
          this.getHospitalList(data.hospital)
        }
        this.byMobileDetail = data
        this.$message.error('该客户在平台已注册')
        this.mergeToForm(data)
      } catch (error) {
        console.log('error: ', error)

      } finally {
        this.isLoadCustomerInfoLoading = false
      }
    },
    async visibleChange(orgRow) {
      if (!orgRow.bindOrgId) {
        this.$message.error('请先选择组织')
        return
      }
      const list = await this.getOrgRepresentList(orgRow.bindOrgId)
      this.$set(orgRow, 'repList', list)
    },
    // 获取当前组织的下级组织列表
    async getOrgChildList() {
      try {
        const res = await this.$axios.get(this.$API.getOrgList)
        // 递归处理数据，判断是否有子组织，没有则删除child字段
        const recursion = list => {
          list.forEach(item => {
            if (item.child && item.child.length > 0) {
              recursion(item.child)
            } else {
              delete item.child
            }
          })
        }
        recursion(res.data?.child || [])
        this.orgChildList = [res.data]
      } catch (error) {
        console.log('error: ', error)
      }
    },
    // 根据组织id获取服务代表列表
    async getOrgRepresentList(orgId) {
      try {
        this.orgRepLoading = true
        const params = {
          orgIds: orgId
        }
        const {data} = await this.$axios.get(this.$API.screeningRepresents, {params})
        return data
      } catch (error) {
        this.$message.error(error.message)
        console.log('error: ', error)
      } finally {
        this.orgRepLoading = false
      }
    },
    // 获取医院列表
    async getHospitalList(hospitalName = this.form.hospitalInfo.name) {
      if (hospitalName === '') {
        this.hospitalList = []
        return
      }
      try {
        this.hospitalLoading = true
        const {data} = await this.$axios.get(this.$API.commonV1HospitalList, {params: {hospitalName}})
        this.hospitalList = data
      } catch (error) {
        console.log('error: ', error)
      } finally {
        this.hospitalLoading = false
      }
    },
    // 查询平台科室列表
    async getHospitalDepartmentList() {
      try {
        this.hospitalDepartmentLoading = true
        const {data} = await this.$axios.get(this.$API.platformDepartment)
        // 递归处理数据，判断是否有子科室，没有则删除childes字段
        const recursion = list => {
          list.forEach(item => {
            if (item.childes && item.childes.length > 0) {
              recursion(item.childes)
            } else {
              delete item.childes
            }
          })
        }
        recursion(data)
        this.$nextTick(() => {
          this.hospitalDepartmentList = data
        })
      } catch (error) {
        console.log('error: ', error)
      } finally {
        this.hospitalDepartmentLoading = false
      }
    },
    async onSubmit() {
      this.$refs['form'].validate(async valid => {
        if (!valid) {
          return false
        }
        try {
          const params = cloneDeep(this.form)
          if (params.oneTwoLevelDepartment) {
            params.oneTwoLevelDepartment[0] && (params.oneLevelDepartmentId = params.oneTwoLevelDepartment[0])
            params.oneTwoLevelDepartment[1] && (params.twoLevelDepartmentId = params.oneTwoLevelDepartment[1])
            delete params.oneTwoLevelDepartment
          }
          if (typeof params.hospitalInfo === 'string') {
            // TODO: 机构名称没对上,待处理
            params.institution = params.hospitalInfo
          } else {
            params.institutionId = params.hospitalInfo.id
            params.institution = params.hospitalInfo.name
          }
          delete params.hospitalInfo
          const res = await this.$axios.post(this.$API.importSingleDoctor, params)
          if (res.data) {
            this.$message.success('操作成功')
            this.visible = false
            this.$emit('onConfirm')
          }
        } catch (error) {
          console.log('error: ', error)
          this.$message.error(error.message)
        }
      })

    }
  }
}
</script>
<style scoped lang="scss">
// scss
.single-add-doctor {
  ::v-deep .customer-form {
    .customer-info {
      > .el-form-item__label {
        width: 100%;
      }
      .input,
      .select {
        width: 178px;
      }
    }
    .service-rep {
      max-height: 200px;
      overflow: scroll;
      > .el-form-item__label {
        position: sticky;
        top: 0;
        background-color: #fff;
        width: 100%;
        z-index: 1;
      }
      .btn-box {
        > .el-link {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
