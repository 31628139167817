<template>
    <div>
        <el-dialog class="add-staff-box" :title="title" :visible.sync="isShowPop" :show-close="false">
            <div class="mgn-l30">
                <el-form :model="form" class="el-form">
                    <el-form-item label="员工姓名：" prop="representName" :label-width="formLabelWidth">
                        <el-input v-model="form.representName" autocomplete="off" placeholder="请输入员工姓名"
                                  class="el-input"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号码：" :label-width="formLabelWidth">
                        <el-input v-model="form.mobile" autocomplete="off" maxlength='11' placeholder="请输入手机号码"
                                  class="el-input"
                                  @input="getRepresentOrg"></el-input>
                    </el-form-item>
                    <el-form-item label="所属组织：" :label-width="formLabelWidth" v-for="(item,index) in orgForm.orgInfoVOS" :key="item.orgId + index"
                                  v-show="!!orgForm.orgInfoVOS">
                        <el-select v-model="item.orgName" placeholder="请选择所属组织" disabled>
                        </el-select>
                        <span class="change-org" @click="changeOrg(item.orgId)" v-show="orgForm.orgInfoVOS"> 变更 </span>
                    </el-form-item>
                    <el-form-item label="所属组织：" :label-width="formLabelWidth" :key="index"
                                  v-for="(domain, index) in currentForm.domains">
                        <el-cascader
                            v-model="domain.orgId"
                            :options="orgList"
                            :props="defaultProps"
                            :show-all-levels="false"
                            placeholder="请选择所属组织"
                            @change="(value)=>handleChange(value,index)"
                            class="el-select"
                            :disabled="domain.isSelect"
                        ></el-cascader>
                        <span class="change-org" @click.prevent="removeDomain(domain)"> 删除 </span>
                    </el-form-item>
                    <el-form-item :label-width="formLabelWidth">
                        <el-button @click="addSelectOrg">添加所属组织 +</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" @click="submitForm" :loading="loading">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AddStaffDialog',
  props: {
    isShowPop: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '添加员工'
    },
    form: {
      type: Object,
      default() {
        return {}
      }
    },
    filterOrgIds: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      open: false,
      orgForm: {
        orgInfoVOS: []
      },
      currentForm: {
        domains: []
      },
      formLabelWidth: '120px',
      rules: {
        orgName: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ]
      },
      visible: true,
      showChangeOrgDialog: false,
      radio: '1',
      defaultProps: {
        children: 'child',
        label: 'orgName',
        value: 'orgId',
        disabled: 'isSelect',
        checkStrictly: true
      },
      currentOrgList: [],
      loading: false,
      orgList: [],
      orgIds: [],
      representId: ''
    }
  },
  computed: {
    isPhoneNumber() {
      return /^1[0-9]\d{9}$/gi.test(this.form.mobile)
    }
  },
  mounted() {
    if (this.title === '添加员工') {
      this.currentForm.domains = [{
        orgId: this.form.orgId
      }]
    }
    this.init(1)
  },
  methods: {
    ...mapActions(['createUpdateRepresent', 'getOrgList', 'getRepresentInfo', 'getBatchConditionTreeList']),
    addSelectOrg() {
      this.currentForm.domains.push({
        orgId: '',
        key: Date.now()
      })
    },
    removeDomain(item) {
      const index = this.currentForm.domains.indexOf(item)
      if (index !== -1) {
        this.currentForm.domains.splice(index, 1)
      }
      this.getNewTree(item)
    },
    getNewTree(item) {
      this.orgIds = [...this.orgIds, ...this.filterOrgIds]
      if (item) {
        console.log(item)
        if (typeof item.orgId === 'string') {
          this.orgIds = this.orgIds.filter(v => v !== item.orgId)
        } else {
          this.orgIds = this.orgIds.filter(v => v !== item.orgId[item.orgId.length - 1])
        }
      }
      this.getBatchConditionTreeList({ filterOrgIds: [...new Set(this.orgIds)] }).then(res => {
        this.orgList = [res.data]
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    getRepresentOrg() {
      if (!this.isPhoneNumber || this.title === '编辑员工') {
        return
      }
      this.orgForm = {}
      this.getRepresentInfo({ mobile: this.form.mobile }).then(res => {
        if (res.data.orgInfoVOS) {
          let orgInfoVOS = []
          orgInfoVOS = (res.data.orgInfoVOS || []).filter(v => v.status === 1)
          this.orgForm = { ...this.orgForm, orgInfoVOS: orgInfoVOS }
          this.representId = res.data.representId
        } else {
          this.representId = ''
        }
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    init(value) {
      this.orgForm = { ...this.orgForm, ...this.form }
      let orgInfo = []
      this.orgIds = []
      if (this.orgForm.orgInfoVOS) {
        this.currentForm.domains.map(v => {
          if (typeof v.orgId === 'string') {
            orgInfo = [...orgInfo, v.orgId]
          } else if (v.orgId.length) {
            orgInfo = [...orgInfo, v.orgId[v.orgId.length - 1]]
          }
        })
        this.orgForm.orgInfoVOS.map(v => {
          this.orgIds.push(v.orgId)
        })
        this.orgIds.push(...orgInfo)
      } else {
        this.currentForm.domains.map(v => {
          if (typeof v.orgId === 'string') {
            this.orgIds = [...this.orgIds, v.orgId]
          } else if (v.orgId.length) {
            this.orgIds = [...this.orgIds, v.orgId[v.orgId.length - 1]]
          }
        })
      }
      if (value === 1) {
        this.getNewTree()
      }
    },
    submitForm() {
      this.init(2)
      if (!this.form.representName) {
        return this.$message.error('请输入员工姓名')
      }
      if (!this.form.mobile) {
        return this.$message.error('请输入手机号')
      }
      if (!this.isPhoneNumber) {
        return this.$message.error('手机号码不正确')
      }
      if (!this.orgIds.length || !this.orgIds[0]) {
        return this.$message.error('请选择所属组织')
      }
      for (let i = 0; i < this.orgIds.length; i++) {
        for (let j = i + 1; j < this.orgIds.length; j++) {
          if (this.orgIds[i] == this.orgIds[j]) {
            return this.$message.error('所属组织重复')
          }
        }
      }
      const req = {
        representId: this.form.representId || this.representId,
        representName: this.form.representName,
        mobile: this.form.mobile,
        orgIds: this.orgIds
      }
      this.loading = true
      this.createUpdateRepresent(req).then(() => {
        this.$message.success('操作成功')
        this.loading = false
        this.$emit('update')
        this.$emit('onClose', false)
      }, rea => {
        this.loading = false
        this.$message.error(rea.message)
      })
    },
    close() {
      this.$emit('onClose', false)
    },
    changeOrg(orgId) {
      let representId = ''
      representId = this.title === '编辑员工' ? this.form.representId : this.representId
      this.$emit('onChangeOrg', this.orgList, orgId, this.orgForm.orgInfoVOS, representId)
    },
    handleChange(value) {
      this.currentOrgList = value
      this.init(1)
    }
  }
}
</script>

<style lang="scss" scoped>
.add-staff-box {
    ::v-deep .el-dialog .el-dialog__header {
        background-color: #ffffff;
        text-align: left;
        border-bottom: 1px solid #e1e1e1;

        .el-dialog__title {
            color: #0F0F0F;
            font-size: 14px;
        }

        .el-dialog__close {
            color: #909399 !important;
        }
    }

    ::v-deep.el-dialog {
        width: 40% !important
    }

    ::v-deep .el-form-item__content {
        width: 60%;
    }

    ::v-deep .el-input__inner {
        background-color: #ffffff !important;
        border: none;
    }
}

.el-input {
    border-bottom: 1px solid #e1e1e1;
}

.el-select {
    border: 1px solid #e1e1e1;
    display: inline-block;
    width: 220px;
}

.dialog-footer {
    text-align: center;
}

.change-org {
    color: #3D61E3;
    margin-left: 10px;
    cursor: pointer;
}
</style>
