<template>
  <div class="staff-detail-box">
    <page-main back title class="staff-info-box">
      <div style="position: relative;">
        <div>
          <span class="label-info">员工姓名：</span>
          <span class="info-text">{{ representInfo.representName }}</span>
        </div>
        <div>
          <span class="label-info">手机号码：</span>
          <span class="info-text">{{ representInfo.mobile }}</span>
        </div>
        <div class="freeze-org-staff-box">
          <span class="label-info">所在组织：</span>
          <div>
            <template v-for="item of representInfo.orgInfoVOS">
              <div :key="item.orgId">
                <span class="info-text">{{ item.orgName }}{{ item.status !== 1 ? '（已冻结）' : '（在职）' }}</span>
                <span
                  v-if="item.status === 1"
                  class="freeze-btn is-pointor"
                  @click="onBtnFreezeStaff(item,false,!item.codeCount && !item.doctorCount)"
                >{{ !item.codeCount && !item.doctorCount ? '删除员工' : '冻结员工' }}</span>
                <span
                  v-else
                  class="no-freeze-btn is-pointor"
                  @click="onBtnFreezeStaff(item,true)"
                >解冻员工</span>
              </div>
            </template>
          </div>
        </div>
        <div class="operate-staff-box">
          <el-button size="small" icon="el-icon-edit" @click="onEditStaff">编辑员工</el-button>
        </div>
      </div>
    </page-main>
    <page-main v-if="$auth(['crm.orgMgmt.employee.viewRepClients', 'crm.orgMgmt.employee.viewTaskResources'])" class="table-box">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-if="$auth('crm.orgMgmt.employee.viewRepClients')" label="客户列表" name="first">
          <div class="custom-table">
            <div>
              <el-input
                v-model="searchObj.searchKey"
                class="mgn-r20"
                placeholder="搜索姓名、职称、科室或医院"
                clearable
                style="width: 220px;"
                @input="onSearchOption"
              >
                <el-button
                  slot="prefix"
                  type="text"
                  icon="el-icon-search"
                  style="color: #a3a3a3; padding-left: 3px;"
                  @click="onSearchOption"
                />
              </el-input>
              <el-select v-model="formObj.orgId" placeholder="请选择组织" @change="onSearchOption">
                <el-option key="all" label="全部组织" value="" />
                <el-option
                  v-for="item in representInfo.orgInfoVOS || []"
                  :key="item.orgId"
                  :label="item.orgName"
                  :value="item.orgId"
                />
              </el-select>
            </div>
            <el-button
              v-if="$auth('crm.orgMgmt.employee.brb')"
              class="customize-el-button"
              type="primary"
              size="medium"
              @click="handleCommand"
            >
              <i class="el-icon-plus el-icon--right" />
              批量更换绑定
            </el-button>
          </div>
          <el-table
            ref="doctorTable"
            v-loading="isReqing"
            :data="tableData"
            stripe
            style="width: 100%;"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="60"
            />
            <el-table-column
              prop="name"
              label="姓名"
              align="center"
              height="50"
              width="180"
            >
              <template slot-scope="scope">
                <div>
                  {{ scope.row.name }}
                  <span v-if="scope.row.isCancel" style="color: #ff4921; padding-left: 3px;">(已注销)</span>
                  <span v-if="scope.row.isFrozen" style="color: #fb9c35; padding-left: 3px;">(已冻结)</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="titleName"
              label="职称"
              align="center"
              width="180"
            />
            <el-table-column
              prop="department"
              align="center"
              label="科室"
            />
            <el-table-column
              prop="hospital"
              align="center"
              label="医院"
            />
            <el-table-column
              prop="bindOrgName"
              align="center"
              show-overflow-tooltip
              label="绑定组织"
            />
            <el-table-column
              prop=""
              align="center"
              label="操作"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="onChangeBind(scope.row)"
                >
                  更换绑定
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="pagination-box"
            :current-page="searchObj.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="searchObj.pageSize"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="onCurrentChange"
          />
        </el-tab-pane>
        <el-tab-pane v-if="$auth('crm.orgMgmt.employee.viewTaskResources')" label="任务资源列表" name="second">
          <div class="custom-table">
            <div class="tab-list-box">
              <template v-for="item in tabList">
                <div
                  :key="item.type"
                  class="tab-item"
                  :class="{active: sceneType === item.type}"
                  @click="onClickTab(item)"
                >
                  {{ item.name }}
                </div>
              </template>
            </div>
            <el-button v-if="$auth('crm.orgMgmt.employee.brtres')" type="primary" @click="onSelectRecallAll">
              <i class="el-icon-plus el-icon--right" />
              批量撤回
            </el-button>
          </div>
          <el-table
            ref="secentTable"
            v-loading="isReqing"
            :data="tableData"
            stripe
            style="width: 100%;"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="60"
            />
            <el-table-column
              prop="sceneTitle"
              label="活动名称"
              align="center"
              width="180"
            />
            <el-table-column
              prop="sceneType"
              label="活动类型"
              align="center"
              width="180"
            >
              <template slot-scope="scope">
                <div>
                  {{ scope.row.sceneType | sceneTypeFilter }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="count"
              align="center"
              width="100"
              label="任务数量"
            />
            <el-table-column
              prop=""
              align="center"
              label="任务规则"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.sceneType !== 7">
                  代表执行{{
                    scope.row.representValidDay
                  }}天+医生学习{{ scope.row.validDay }}天
                </div>
                <div v-else>{{ scope.row.money }}积分</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="orgName"
              align="center"
              show-overflow-tooltip
              label="所属组织"
            />
            <el-table-column
              prop=""
              align="center"
              width="100"
              label="操作"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.count"
                  type="text"
                  @click="onRecall(scope.row)"
                >
                  撤回
                </el-button>
                <span v-else>-</span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="pagination-box"
            :current-page="searchObj.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="searchObj.pageSize"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="onCurrentChange"
          />
        </el-tab-pane>
      </el-tabs>
    </page-main>
    <ChangeBing
      v-if="isShowBing"
      :form-obj="selectObj"
      :is-show-pop="isShowBing"
      @onChangBang="onChangBang"
      @onClose="(val) => {isShowBing = val}"
    />
    <RecallTask
      v-if="isShowRecall"
      :form-obj="selectObj"
      :is-show-pop="isShowRecall"
      @onRecallConfirm="onRecallConfirm"
      @onClose="(val) => {isShowRecall = val}"
    />
    <RecallTaskTip
      v-if="isShowRecallTip"
      :form-obj="selectObj"
      :is-show-pop="isShowRecallTip"
      @onConfirm="onConfirm"
      @onClose="(val) => {isShowRecallTip = val}"
    />
    <FreezeDialog
      v-if="isShowFreeze"
      :is-show-pop="isShowFreeze"
      :is-more="isMore"
      :is-freeze="isFreeze"
      :form-obj="freezeObj"
      @onFreezeStaff="onFreezeStaff"
      @onClose="(val) => {isShowFreeze = val}"
    />
    <DelRepresentTip
      v-if="isShowDelRepresent"
      :is-show-pop="isShowDelRepresent"
      :form-obj="freezeObj"
      @onConfirm="onConfirmDel"
      @onClose="(val) => {isShowDelRepresent = val}"
    />
    <AddStaffDialog
      v-if="isShowAdd"
      :is-show-pop="isShowAdd"
      :title="title"
      :form="form"
      :filter-org-ids="filterOrgIds"
      @onClose="(val) => {isShowAdd = val}"
      @update="onGetDetail"
      @onChangeOrg="onChangeOrg"
    />
    <ChangeOrgDialog
      v-if="showChangeOrgDialog"
      :show-change-org-dialog="showChangeOrgDialog"
      :from-og-id="fromOgId"
      :represent-id="form.representId"
      :org-list="currentOrgList"
      @onCloseChange="onCloseChange"
      @onChange="onChange"
    />
  </div>
</template>

<script>
import ChangeBing from './components/ChangeBing'
import RecallTask from './components/RecallTask'
import RecallTaskTip from './components/RecallTaskTip'
import FreezeDialog from './components/FreezeDialog'
import DelRepresentTip from './components/DelRepresentTip'
import AddStaffDialog from './components/AddStaffDialog'
import ChangeOrgDialog from './components/ChangeOrgDialog'
import { mapActions } from 'vuex'

export default {
  name: 'StaffDetail',
  components: {
    ChangeBing,
    RecallTask,
    RecallTaskTip,
    FreezeDialog,
    DelRepresentTip,
    AddStaffDialog,
    ChangeOrgDialog
  },
  filters: {
    sceneTypeFilter(type) {
      switch (type) {
        case 1:
          return '科室会'
        case 2:
          return '旧调研'
        case 3:
          return '旧拜访'
        case 4:
          return '问卷调研'
        case 5:
          return '定向直播'
        case 6:
          return '多人会议'
        case 7:
          return '员工培训'
        case 8:
          return '学术拜访'
        case 9:
          return '代表知识商城'
        case 10:
          return '知识店铺'
        default:
          return '一'
      }
    }
  },
  data() {
    return {
      activeName: 'first',
      representInfo: {},
      staffInfo: {},
      isFreeze: false,
      isMore: false,
      freezeObj: {},
      formObj: {
        representId: '',
        mobile: '',
        orgId: ''
      },
      timer: null,
      searchObj: {
        searchKey: '',
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      selectList: [],
      tableData: [],
      selectObj: {},
      sceneType: 'all',
      tabList: [
        {
          type: 'all',
          name: '全部'
        },
        {
          type: '1',
          name: '科室会'
        },
        {
          type: '4',
          name: '问卷调研'
        },
        {
          type: '8',
          name: '学术拜访'
        },
        {
          type: '5',
          name: '定向直播'
        },
        {
          type: '6',
          name: '多人会议'
        }
      ],
      isShowBing: false,
      isShowRecall: false,
      isShowRecallTip: false,
      loading: false,
      isShowFreeze: false,
      isShowDelRepresent: false,
      isShowAdd: false,
      title: '',
      currentOrgList: [],
      showChangeOrgDialog: false,
      form: {},
      fromOgId: '',
      filterOrgIds: [],
      isReqing: false
    }
  },
  computed: {
    tableDom() {
      if (this.activeName === 'first') {
        return this.$refs.doctorTable
      } else {
        return this.$refs.secentTable
      }
    },
    multipleSelection: {
      get() {
        return this.selectList
      },
      set(list) {
        this.selectList = list
        this.onClearNode()
        if (list.length) {
          this.onCreateNode()
        }
      }
    }
  },
  mounted() {
    this.formObj = this.freezeObj = {
      representId: this.$route.params.id,
      mobile: this.$route.query.mobile,
      orgId: this.$route.query.orgId
    }
    this.onGetDetail()
  },
  methods: {
    ...mapActions(['getRepresentInfo', 'getRepresentDoctors', 'representSceneList', 'recallRepresentCode', 'getRepresentDetailInfo', 'doctorChangeRepresent']),
    onRecallConfirm() {
      this.multipleSelection = []
      this.onGetTableList()
    },
    onSearchOption() {
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.onInitOptions({ searchKey: this.searchObj.searchKey })
        this.onGetTableList()
      }, 500)
    },
    onGetDetail() {
      this.getRepresentDetailInfo({ representId: this.formObj.representId }).then(res => {
        this.representInfo = res.data
        this.onGetTableList()
      }, rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    onGetDoctorList() {
      this.isReqing = true
      const data = {
        ...this.searchObj,
        representId: this.formObj.representId,
        orgId: this.formObj.orgId
      }
      this.getRepresentDoctors(data).then(res => {
        this.isReqing = false
        this.total = res.data.totalCount
        this.tableData = res.data?.data
      }, rea => {
        this.isReqing = false
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    onGetTableList() {
      if (this.activeName === 'first') {
        this.onGetDoctorList()
      } else {
        this.onGetSceneList()
      }
    },
    onGetSceneList() {
      this.isReqing = true
      const data = {
        sceneType: this.sceneType === 'all' ? '' : this.sceneType,
        ...this.searchObj,
        representId: this.formObj.representId
      }
      this.representSceneList(data).then(res => {
        this.isReqing = false
        this.total = res.data.total
        this.tableData = res.data?.data
      }, rea => {
        this.isReqing = false
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    onCreateNode() {
      this.$nextTick(() => {
        if (!this.tableDom) return
        const targetDom = this.tableDom.$el.getElementsByClassName('el-table__header-wrapper')[0]
        const divTemp = document.createElement('div')
        divTemp.onselectstart = function() {
          return false
        }
        divTemp.className = 'table-select-text'
        const span1 = document.createElement('span')
        span1.innerHTML = `已选当前页面共${this.multipleSelection.length}条数据`
        divTemp.appendChild(span1)
        targetDom.appendChild(divTemp)
      })
    },
    onClearNode() {
      if (!this.tableDom) return
      const targetDom = this.tableDom.$el.getElementsByClassName('el-table__header-wrapper')[0]
      const children = targetDom.getElementsByClassName('table-select-text')[0]
      if (children) {
        targetDom.removeChild(children)
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    onInitOptions({ searchKey, pageNo = 1, pageSize = 10, total = 0, multipleSelection = [] } = {}) {     // 重置搜索数据
      this.searchObj = {
        searchKey,
        pageNo,
        pageSize
      }
      this.total = total
      this.multipleSelection = multipleSelection
    },
    handleClick() {
      this.onInitOptions()
      this.onGetTableList()
    },
    onSelectRecallAll() {
      if (!this.multipleSelection.length) {
        this.$message({
          message: '请先进行勾选',
          type: 'error'
        })
        return
      }
      this.isShowRecallTip = true
    },
    onConfirm() {
      if (this.loading) return
      this.loading = true
      const data = []
      this.multipleSelection?.map(item => {
        data.push({
          sceneId: item.sceneId,
          sceneType: item.sceneType,
          money: item.money,
          validDay: item.validDay,
          representValidDay: item.representValidDay,
          purchaseId: '',
          finalOrgId: item.orgId,
          isPreDoctorCode: item.isPreDoctorCode,
          representId: this.formObj.representId,
          recallCount: item.count
        })
      })
      this.recallRepresentCode(data).then(() => {
        this.isShowRecallTip = false
        this.loading = false
        this.$message({
          message: '撤回成功',
          type: 'success'
        })
        this.multipleSelection = []
        this.onGetTableList()
      }, rea => {
        this.loading = false
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    handleCommand() {
      if (!this.multipleSelection.length) {
        this.$message({
          message: '请先进行勾选',
          type: 'error'
        })
        return
      }
      this.isShowBing = true
      this.selectObj = this.multipleSelection[0]
      this.selectObj.doctorList = this.multipleSelection
    },
    onChangeBind(row) {
      this.isShowBing = true
      this.selectObj = row
      this.selectObj.doctorList = [row]
    },
    onChangBang(selectRepObj) {
      const list = []
      for (const item of this.selectObj.doctorList) {
        list.push({
          doctorId: item.id,
          fromRepresentOrgId: item.bindOrgId
        })
      }
      const data = {
        transformDoctorBOS: list,
        fromRepresentId: this.selectObj.representId,
        toRepresentId: selectRepObj.representId || selectRepObj.id,
        toRepresentOrgId: selectRepObj.orgId
      }
      this.doctorChangeRepresent(data).then(() => {
        this.isShowBing = false
        this.$message.success('变更绑定成功')
        this.multipleSelection = []
        this.onGetTableList()
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    handleSizeChange(option) {
      this.searchObj.pageNo = 1
      this.searchObj.pageSize = option
      if (this.activeName === 'first') {
        this.onGetDoctorList()
      } else {
        this.onGetSceneList()
      }
    },
    onCurrentChange(option) {
      this.searchObj.pageNo = option
      if (this.activeName === 'first') {
        this.onGetDoctorList()
      } else {
        this.onGetSceneList()
      }
    },
    onClickTab(item) {
      this.onInitOptions()
      this.sceneType = item.type
      this.onGetSceneList()
    },
    onBtnFreezeStaff(item, isFreeze, isDel = false) {
      if (item.orgStatus !== 1) {
        return this.$message.error('该组织处于冻结状态，不支持操作！')
      }

      this.isFreeze = isFreeze
      if (isDel) {
        this.isShowDelRepresent = true
      } else {
        this.isShowFreeze = true
      }
      this.freezeObj = { ...this.freezeObj, ...item }
    },
    onFreezeStaff() {
      this.onGetDetail()
    },
    onConfirmDel() {
      if (this.representInfo.orgInfoVOS.length === 1) {
        this.$router.back()
        return
      }
      this.onGetDetail()
    },
    onEditStaff() {
      this.getRepresentInfo({ mobile: this.representInfo.mobile }).then(res => {
        this.form = {...res.data, representMobile: res.data.mobile, orgInfoVOS: (res.data.orgInfoVOS || []).filter(v => v.status === 1)}
        this.isShowAdd = true
        this.title = '编辑员工'
        this.filterOrgIds = []
        res.data.orgInfoVOS.map(v => {
          if (v.status < 1) {
            this.filterOrgIds.push(v.orgId)
          }
        })
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    onGetStaffList(data) {
      console.log(data)
    },
    onChangeOrg(currentOrgList, id, orgInfoVOS, representId) {
      this.fromOgId = id
      this.currentOrgList = currentOrgList
      this.isShowAdd = false
      this.showChangeOrgDialog = true
      this.form.orgInfoVOS = orgInfoVOS
      this.form.representId = representId
    },
    onChange(orgId, orgName) {
      this.form.orgInfoVOS = this.form.orgInfoVOS.map(i => {
        if (i.orgId === this.fromOgId) {
          i.orgId = orgId
          i.orgName = orgName
          i.isSelect = true
        }
        return i
      })
      this.onCloseChange()
    },
    onCloseChange() {
      this.showChangeOrgDialog = false
      this.isShowAdd = true
    },
    onRecall(row) {
      this.isShowRecall = true
      this.selectObj = { ...row, representId: this.formObj.representId }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table .el-table__cell {
  padding: 5px 0;
  height: 51px;
}
.staff-detail-box {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}
.staff-info-box,
.table-box {
  background-color: #fff;
  font-size: 14px;
  line-height: 28px;
  position: relative;
  z-index: inherit;
}
.operate-staff-box {
  position: absolute;
  right: 26px;
  top: 28px;
  z-index: inherit;
}
.label-info {
  color: #5a5a5a;
}
.table-box {
  flex: 1;
  padding-top: 0;
  ::v-deep .el-tabs__nav-wrap {
    overflow: inherit;
    &::after {
      width: calc(100% + 2 * #{$g-main-wrap-padding});
      left: -$g-main-wrap-padding;
    }
  }
}
.custom-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  ::v-deep .el-input__inner {
    background-color: #fafafa;
    border-radius: 20px;
  }
}
.pagination-box {
  text-align: right;
  margin-top: 20px;
}
.footer {
  width: 100%;
  padding: 15px;
  background: #fff;
  border-top: 1px solid #eee;
  text-align: center;
}
.tab-list-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.tab-item {
  width: 70px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  color: #5a5a5a;
  background: #fafafa;
  border-radius: 1px;
  margin-right: 10px;
  cursor: pointer;
  -webkit-touch-callout: none; /* 系统默认菜单被禁用 */
  -webkit-user-select: none; /* webkit浏览器 */
  -khtml-user-select: none; /* 早期浏览器 */
  -moz-user-select: none; /* 火狐 */
  -ms-user-select: none; /* IE10 */
  user-select: none;
}
.active {
  background-color: #e7ecfb;
  color: #3d61e3;
}
::v-deep .table-select-text {
  width: 100%;
  height: 25px;
  line-height: 25px;
  background: rgba(255, 153, 0, 0.15);
  padding: 0 30px;
}
.freeze-org-staff-box {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
.org-list-box {
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.freeze-btn {
  color: #3d61e3;
  border-bottom: 1px solid #3d61e3;
  margin-left: 10px;
}
.no-freeze-btn {
  color: #e8312f;
  border-bottom: 1px solid #e8312f;
  margin-left: 10px;
}
.mgn-r20 {
  margin-right: 20px;
}

</style>
