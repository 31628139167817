<template>
  <div class="freeze-dialog-box">
    <el-dialog v-if="!isFreeze" :title="isMore?'批量冻结':'冻结员工'" :visible.sync="dialogFormVisible">
      <div v-if="isMore" class="freeze-info left-align">
        <div class="bold">
            <img src="@/assets/iconImg/warn.png" style="width: 14px;margin-right: 3px;" />
            <span>确定要冻结选中的员工？</span>
        </div>
        <div style="color: #5A5A5A;">冻结后，员工将无法使用趣科会登录该组织</div>
      </div>
      <div v-else class="freeze-info left-align">
        <div class="bold">
            <img src="@/assets/iconImg/warn.png" style="width: 14px;vertical-align:middle;margin-right: 3px;" />
            <span>确定要将{{formObj.representName}}从{{formObj.orgName}}冻结？</span>
        </div>
        <div style="color: #5A5A5A;">冻结后，员工将无法使用趣科会登录该组织</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('freeze')">冻 结</el-button>
      </div>
    </el-dialog>
    <el-dialog v-else title="取消冻结" :visible.sync="dialogFormVisible">
      <div v-if="isMore" class="freeze-info left-align">
        <div class="bold">
            <img src="@/assets/iconImg/warn.png" style="width: 14px;vertical-align:middle;margin-right: 3px;" />
            <span>确定要将选中的员工取消冻结？</span>
        </div>
        <div style="color: #5A5A5A;">取消冻结后，员工将可以继续以该组织员工身份登录趣科会</div>
      </div>
      <div v-else class="freeze-info left-align">
        <div class="bold">
            <img src="@/assets/iconImg/warn.png" style="width: 14px;vertical-align:middle;margin-right: 3px;" />
            <span>确定要取消冻结？</span>
        </div>
        <div style="color: #5A5A5A;">取消冻结后，员工将可以继续以该组织员工身份登录趣科会</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('cancel')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: "FreezeDialog",
  props: {
    isShowPop: {
      type: Boolean,
      default: false
    },
    formObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    multipleSelection: {
      type: Array,
      default: () => {
        return []
      }
    },
    isMore: {
      type: Boolean,
      default: false,
    },
    isFreeze: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    dialogFormVisible: {
      get() {
        this.open = this.isShowPop
        return this.isShowPop
      },
      set(val) {
        this.open = val
        this.$emit('onClose',val)
      }
    }
  },
  data() {
    return {
      open: false,
      title: '冻结',
      loading: false,
    }
  },
  methods: {
    ...mapActions(['freezeStaff','freezeMoreStaff']),

    submitForm(type) {
      console.log(type)
      if(this.loading) return
      if(this.isMore) {
        let data = []
        for(let item of this.multipleSelection) {
            console.log(item)
            if(item.orgStatus === 1) {
                data.push({
                    targetOrgId: item.orgId,
                    representId: item.representId,
                    freeze: type==='freeze'
                })
            }

        }
        if(!data.length && this.multipleSelection.length && this.multipleSelection[0].orgStatus !== 1) {
            return this.$message.error("选中员工所在组织处于冻结状态，不支持操作！")
        }
        this.loading = true
        this.freezeMoreStaff(data).then(res => {
          this.loading = false
          this.dialogFormVisible = false
          this.$message({
            message: type === 'freeze' ? "冻结成功" : "取消冻结成功",
            type: 'success'
          })
          this.$emit('onFreezeStaff')
        },rea => {
          this.loading = false
          this.$message({
            message: rea.message,
            type: 'error'
          })
        })
      } else {
        this.freezeStaff({targetOrgId: this.formObj.orgId,representId:this.formObj.representId,freeze: type === 'freeze'}).then(res => {
          this.loading = false
          this.dialogFormVisible = false
          this.$message({
            message: type === 'freeze' ? "冻结成功" : "取消冻结成功",
            type: 'success'
          })
          this.$emit('onFreezeStaff')
        },rea => {
          this.loading = false
          this.$message({
            message: rea.message,
            type: 'error'
          })
        })
      }
    },
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.freeze-dialog-box {
    ::v-deep .el-dialog {
        width: fit-content;
        min-width: 460px;
    }
  ::v-deep .el-dialog .el-dialog__header {
    background-color: #ffffff;
    text-align: left;
    border-bottom: 1px solid #e1e1e1;
    .el-dialog__title {
      color: #0F0F0F;
      font-size: 14px;
    }
    .el-dialog__close {
      color: #909399 !important;
    }
  }
}
.freeze-info {
  width: fit-content;
  margin: auto;
  font-weight: bold;
  font-size: 14px;
  padding: 36px 0 30px;
  color: #0F0F0F;
  text-align: left;
  line-height: 22px;
}
.left-align {
    text-align: left;
    width: fit-content;
    margin: auto;
}
.bold {
    font-weight: bold;
    display: flex;
    align-items: center;
}
.dialog-footer {
  text-align: center;
}

.center {
  text-align: center;
}
</style>
