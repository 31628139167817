<template>
  <div class="delete-dialog-box">
    <el-dialog  title="批量撤回" :visible.sync="dialogFormVisible">
      <div class="freeze-info left-align">
        <div class="bold">
            <img src="@/assets/iconImg/warn.png" style="width: 14px;margin-right: 3px;" />
            <span>确认批量撤回任务资源吗？</span>
        </div>
        <div style="color: #5A5A5A;">批量撤回是将所勾选的任务数全部撤回，若想撤回部分任务，可取消弹窗后进行单独撤回</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: "RecallTaskTip",
  props: {
    isShowPop: {
      type: Boolean,
      default: false
    },
    formObj: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    dialogFormVisible: {
      get() {
        this.open = this.isShowPop
        return this.isShowPop
      },
      set(val) {
        this.open = val
        this.$emit('onClose',val)
      }
    }
  },
  data() {
    return {
      open: false,
      title: '',
      message: ''
    }
  },
  methods: {
    submitForm() {
      this.$emit('onConfirm')
    },
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.delete-dialog-box {
    ::v-deep .el-dialog {
        width: 460px;
    }
    ::v-deep .el-dialog .el-dialog__body {
        padding: 30px 58px;
    }
  ::v-deep .el-dialog .el-dialog__header {
    background-color: #ffffff;
    text-align: left;
    border-bottom: 1px solid #e1e1e1;
    .el-dialog__title {
      color: #0F0F0F;
      font-size: 14px;
    }
    .el-dialog__close {
      color: #909399 !important;
    }
  }
}
.freeze-info {
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  padding: 36px 0 30px;
  color: #0F0F0F;
  line-height: 22px;
}
.left-align {
    text-align: left;
    width: fit-content;
    margin: auto;
}
.bold {
    font-weight: bold;
    display: flex;
    align-items: center;
}
.dialog-footer {
  text-align: center;
}
</style>
