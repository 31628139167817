<template>
  <el-dialog
    class="customize-el-dialog assist-account-box"
    :title="form.login?'编辑管理员':'新建管理员'"
    :visible.sync="dialogFormVisible"
  >
    <el-form ref="ruleForm" :model="form" :rules="rules">
      <el-form-item label="组织名称：" :label-width="formLabelWidth">
        <span>{{ form.orgName }}</span>
      </el-form-item>
      <el-form-item label="姓名：" prop="name" :label-width="formLabelWidth">
        <el-input v-model="form.name" autocomplete="off" placeholder="请输入姓名" />
      </el-form-item>
      <el-form-item label="手机：" prop="mobile" :label-width="formLabelWidth">
        <el-input
          v-model="form.mobile"
          :maxlength="11"
          type="tel"
          autocomplete="off"
          placeholder="请输入手机号"
        />
      </el-form-item>
      <!-- <el-form-item label="账号：" :label-width="formLabelWidth">
        <span>{{form.login || '创建后自动生成'}}</span>
      </el-form-item> -->
      <el-form-item label="登录密码：" :label-width="formLabelWidth">
        <el-input
          v-model="form.password"
          autocomplete="off"
          :type="form.password ? 'password' : inputType"
          show-password
          placeholder="选填，可用手机验证码登录"
          @focus="onFocus"
        />
      </el-form-item>
      <el-form-item
        v-if="form.userType !== 1"
        label="权限："
        prop="userRole"
        :label-width="formLabelWidth"
      >
        <el-select
          v-model="form.userRole"
          value-key="roleId"
          class="assist-select"
          placeholder="请选择权限"
        >
          <el-option
            v-for="item in permissionRoles || []"
            :key="item.roleId"
            :label="item.roleName"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-button
        v-if="form.userType !== 1"
        type="text"
        style="margin-left: 120px; margin-top: -12px;"
        @click="onJumpHelp"
      >
        点击查看权限解释
      </el-button>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="submitForm('ruleForm')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { checkPassWordStrength, CtoH, cryptoEncrypt } from '@/utils/util'

export default {
  name: 'AssistAccount',
  props: {
    isShowPop: {
      type: Boolean,
      default: false
    },
    manageterType: {
      type: String,
      default: 'add-master'
    },
    formObj: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      open: false,
      title: '新建组织',
      form: {
        orgName: '',
        orgId: '',
        name: '',
        mobile: '',
        password: '',
        apply: '',
        userRole: {}
      },
      inputType: 'text',
      formLabelWidth: '120px',
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        userRole: [
          {required: true, message: '请选择权限', trigger: 'change'}
        ]
      },
      assistVal: '',
      loading: false,
      permissionRoles: []
    }
  },
  computed: {
    // ...mapGetters(['permissionRoles']),
    dialogFormVisible: {
      get() {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.open = this.isShowPop
        return this.isShowPop
      },
      set(val) {
        this.open = val
        if (!val) {
          this.$refs['ruleForm'].resetFields()
        }
        this.$emit('onClose', val)
      }
    }
  },
  mounted() {
    this.form = Object.assign({}, this.form, this.formObj)
    this.form.userRole = this.formObj.userRoleId ? {roleName: this.formObj.roleName, roleId: this.formObj.userRoleId.toString(), roleType: this.formObj.roleType} : {}
    this.getRoleList()
  },
  methods: {
    ...mapActions(['createOrModify']),
    // ----------------------------- 工具 ------------------
    getRoleList() {
      this.$axios.get(this.$API.roleList).then(res => {
        this.permissionRoles = res.data
      })
    },
    isvalidPhone(str) {
      const reg = /^1[0-9]\d{9}$/
      return reg.test(str)
    },
    // -----------------------------------------------------
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.form.mobile && !this.isvalidPhone(this.form.mobile)) {
            this.form.mobile = this.form.mobile.trim()
            this.$message.error('请填写正确手机号')
            return
          }
          if (this.loading) return
          this.$set(this.form, 'name', CtoH(this.form.name || '').trim())
          this.$set(this.form, 'mobile', this.form.mobile.trim())
          this.loading = true
          const data = {
            userId: this.form.userId,
            accountType: this.form.userType || 2,
            targetOrgId: this.form.orgId,
            mobile: this.form.mobile,
            name: this.form.name,
            password: cryptoEncrypt(this.form.password),
            userRoleId: this.form.userRole.roleId,
            userRoleType: this.form.userRole.roleType,
            pwdSecurityDegree: checkPassWordStrength(this.form.password).key || 0
          }
          this.createOrModify(data).then(() => {
            this.loading = false
            this.dialogFormVisible = false
            this.$message.success('操作成功')
            this.$emit('onEditManager')
          }, rea => {
            this.loading = false
            this.$message({
              message: rea.message,
              type: 'error'
            })
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    onJumpHelp() {
      const url = this.$router.resolve('/help/detail/3efa3e3b8ce2247e769681380a41a3f8')
      window.open(url.href, '_blank')
    },
    onFocus() {
      this.inputType = 'password'
    }
  }
}
</script>

<style lang="scss" scoped>
.assist-account-box {
  ::v-deep .el-form-item__label {
    color: #5a5a5a;
  }
  ::v-deep .el-form-item__content .el-input {
    width: 70%;
    border-bottom: 1px solid #e1e1e1;
  }
  ::v-deep .el-input__inner {
    background-color: #fff !important;
    border: none;
  }
  .assist-select {
    width: 70%;
    ::v-deep .el-input {
      width: 100%;
      border-bottom: none;
    }
    ::v-deep .el-input__inner {
      background-color: #fff !important;
      border: 1px solid #e1e1e1;
    }
  }
}
</style>
