<template>
  <page-main back title>
    <div class="table-box">
      <div class="custom-table">
        <div>
          <el-input
            v-model="searchVal"
            placeholder="搜索组织、管理员名称"
            clearable
            style="width: 200px;"
          >
            <el-button
              slot="prefix"
              type="text"
              icon="el-icon-search"
              style="color: #a3a3a3; padding-left: 3px;"
            />
          </el-input>
          <el-date-picker
            :value="[startDate, endDate]"
            class="picker-layout"
            type="daterange"
            unlink-panels
            range-separator="—"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            :disabled-date="pickerOptions.disabledDate"
            :clearable="false"
            :editable="false"
            @change="onChangeDate"
          />
        </div>
        <div>
          <el-button type="primary" plain @click="onExportOrg">导出数据</el-button>
          <el-button
            v-if="$auth('crm.orgMgmt.orgRel.createOrg')"
            type="primary"
            plain
            @click="onImportOrg"
          >
            批量导入组织
          </el-button>
        </div>
      </div>
      <el-table
        ref="multipleTable"
        :data="initOrgList"
        row-key="orgId"
        :indent="32"
        :tree-props="{ children: 'child' }"
        :expand-row-keys="defaultOpen"
        stripe
        style="width: 100%;"
        @expand-change="onNodeExpand"
        @node-collapse="onNodeCollapse"
      >
        <el-table-column
          prop="orgName"
          label="组织名称"
          align="left"
          width="300"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="org-name-box">
              <el-tooltip
                v-if="scope.row.status !== 1"
                effect="light"
                content="该组织已被冻结"
                placement="bottom"
              >
                <img src="@/assets/nav/forbid.png" alt="">
              </el-tooltip>
              {{ scope.row.orgName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="teamName" label="组织管理员" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.orgId === orgManageList[0].orgId">
              <span>{{ scope.row.managerName || '一' }}</span>
            </div>
            <div v-else>
              <span v-if="scope.row.managerName || scope.row.status !== 1">{{ scope.row.managerName || '一' }}</span>
              <el-button v-else-if="$auth('authorityNav')" type="text" @click="onCreateMaster(scope.row)">新建管理员</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" align="center" label="手机号">
          <template slot-scope="scope">
            <div>
              <span>{{ scope.row.managerMobile || '一' }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop=""
          align=""
          width="260"
          label="操作"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.orgId === orgManageList[0].orgId">
              <el-button type="text" @click="onClickCommand({ type: 'look' }, scope.row)">查看数据</el-button>
              <el-button v-if="$auth('crm.orgMgmt.orgRel.createOrg')" type="text" @click="onClickCommand({ type: 'add' }, scope.row)">添加子部门</el-button>
            </template>
            <template v-else>
              <el-button type="text" @click="onClickCommand({ type: 'look' }, scope.row)">查看数据</el-button>
              <el-button
                v-if="clickNodeKey !== scope.row.orgId && hoverList.length !== 0"
                type="text"
                @click="onNodeClick(scope.row)"
              >
                更多操作
              </el-button>
              <template v-for="item in hoverList" v-else>
                <el-button
                  :key="item.type"
                  :class="{ 'text-red': ['del', 'freeze', 'cancelFreeze'].includes(item.type) }"
                  type="text"
                  @click="onClickCommand(item)"
                >
                  {{ item.name }}
                </el-button>
              </template>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 弹窗 -->
    <AssistAccount
      v-if="isShowAssist"
      :is-show-pop="isShowAssist"
      :form-obj="formObj"
      @onEditManager="onEditManager"
      @onClose="(val) => { isShowAssist = val }"
    />
    <AddOrginazation
      v-if="isShowPop"
      :form-obj="formObj"
      :type="operateType"
      :is-show-pop="isShowPop"
      @onClose="(val) => { isShowPop = val }"
    />
    <FreezeOrgDialog
      :form-obj="formObj"
      :is-show-pop="isShowFreezePop"
      :is-freeze="isFreeze"
      @onClose="(val) => { isShowFreezePop = val }"
    />
    <DeleteDialog
      :form-obj="formObj"
      :is-show-pop="isShowDeletePop"
      @onDelOrg="onDelOrg"
      @onClose="(val) => { isShowDeletePop = val }"
    />
    <ImportExcelDialog
      v-if="isShowImport"
      :is-show-pop="isShowImport"
      :model-type="modelType"
      @onComplete="onComplete"
      @onClose="(val) => { isShowImport = val }"
    />
  </page-main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import AssistAccount from './components/AssistAccount'
import AddOrginazation from './components/AddOrginazation'
import FreezeOrgDialog from './components/FreezeOrgDialog'
import DeleteDialog from './components/DeleteDialog'
import ImportExcelDialog from '@/baseComponents/ImportExcelDialog'

import domainRoute from '@/utils/domain-route'
import { getLocalSceneTimeDate } from '@/utils/util'

export default {
  name: 'OrginazationManage',
  components: {
    AddOrginazation,
    FreezeOrgDialog,
    DeleteDialog,
    AssistAccount,
    ImportExcelDialog
  },
  data() {
    return {
      activeName: 'first',
      searchObj: {
        pageNo: 1,
        pageSize: 10
      },
      total: 0,

      isShowAssist: false,
      isShowPop: false,
      isShowImport: false,
      modelType: 'addOrg',

      isShowFreezePop: false,
      isFreeze: true,

      isShowDeletePop: false,

      openNodeKeyArr: [],
      hoverItem: {},
      formObj: {},

      searchVal: '',
      filterList: [],

      operateType: 'add',
      clickNodeKey: '',
      hoverList: [],

      isLoading: false,

      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.startDate = `${this.$moment(minDate).format('YYYY-MM-DD')}`
          this.endDate = `${this.$moment(maxDate).format('YYYY-MM-DD')}`
        },
        disabledDate: current => {
          return (current && current > this.$moment().add(-1, 'days').endOf('day') - 1)
        }
      },
      startDate: `${this.$moment().add(-1, 'months').format('YYYY-MM-DD')}`,
      endDate: `${this.$moment().add(-1, 'days').format('YYYY-MM-DD')}`
    }
  },
  computed: {
    ...mapGetters(['orgManageList']),
    defaultOpen: {
      get() {
        if (this.clickNodeKey) {
          //   return this.openNodeKeyArr
          return [this.orgManageList[0]?.orgId]
        }
        const obj = this.orgManageList[0] || {}
        this.onClickNodeObj(obj)
        return this.openNodeKeyArr
      }
    },
    initOrgList: {
      get() {
        const id = this.orgManageList[0]?.orgId
        if (id) {
          this.remove(id)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.openNodeKeyArr.push(id)
        }
        return this.filterList
      }
    }
  },
  watch: {
    searchVal: {
      immediate: true,
      handler() {
        this.filterList = []
        this.checkList(this.orgManageList)
      }
    },
    orgManageList: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        this.filterList = []
        this.checkList(newVal || oldVal)
        if (this.operateType === 'del') {
          this.onNodeClick({})
        } else if (this.clickNodeKey) {
          this.onNodeClick(this.hoverItem)
        }
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['getOrgManageList', 'getOrgOperate', 'downloadOrgList']),
    // ---------------- 工具函数 ------------------------------
    indexOf(val) {
      for (let i = 0; i < this.openNodeKeyArr.length; i++) {
        if (this.openNodeKeyArr[i] === val) {
          return i
        }
      }
    },
    remove(val) {
      const index = this.indexOf(val)
      if (index > -1) {
        this.openNodeKeyArr.splice(index, 1)
      }
    },
    // ----------------------------------------------------------
    onClickNodeObj(item) {
      this.clickNodeKey = item.orgId
      this.hoverItem = item
    },
    handleCommand(command) {
      this.$message(`click on item ${  command}`)
    },
    onImportOrg() {
      this.isShowImport = true
    },
    onExportOrg() {
      const req = {
        startDate: this.startDate,
        endDate: this.endDate
      }
      this.downloadOrgList(req).then(res => {
        this.$message.success(res.data.data)
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    onComplete() {
      this.pageNo = 1
      this.init()
    },

    onNodeExpand(data) {   // 节点展开
      this.openNodeKeyArr.push(data.orgId)
    },
    onNodeCollapse(data) {   // 节点收起
      this.remove(data.orgId)
    },
    checkList(list) {
      for (const item of list) {
        const val = this.searchVal.trim().toLowerCase()
        if (item.orgName?.toLowerCase().includes(val) || item.managerName?.toLowerCase().includes(val)) {
          this.filterList.push(item)
        } else {
          if (item.child && item.child.length) {
            this.checkList(item.child)
          }
        }
      }
    },
    onGetOperate(id) {
      if (this.isLoading) return
      this.isLoading = true
      this.hoverList = []
      this.getOrgOperate({ orgId: id }).then(res => {
        this.isLoading = false
        const { data = {} } = res
        if (data.canAdd && this.$auth('crm.orgMgmt.orgRel.createOrg')) {
          this.hoverList.push(
            {
              type: 'add',
              name: '添加子部门'
            }
          )
        }
        if (data.canEdit) {
          this.hoverList.push(
            {
              type: 'edit',
              name: '编辑部门'
            }
          )
        }
        if (data.displayDelBtn) {
          this.hoverList.push(
            {
              type: 'del',
              name: '删除'
            }
          )
        }
        if (data.displayFrozenBtn && !data.isFrozen) {
          this.hoverList.push(
            {
              type: 'freeze',
              name: '冻结'
            }
          )
        } else if (data.displayFrozenBtn && data.isFrozen) {
          this.hoverList.push(
            {
              type: 'cancelFreeze',
              name: '取消冻结'
            }
          )
        }
      })
    },
    onNodeClick(data) {
      if (this.isLoading) return
      this.onClickNodeObj(data)
      if (this.clickNodeKey && this.clickNodeKey !== this.orgManageList[0].orgId) {
        this.onGetOperate(data.orgId)
      }
    },
    onClickCommand(item, data) {
      if (data) {
        this.onClickNodeObj(data)
      }
      this.operateType = item.type

      if (item.type === 'add') {
        this.isShowPop = true
        this.formObj = {
          parentOrgName: this.hoverItem.orgName,
          parentOrgId: this.hoverItem.orgId
        }
      } else if (item.type === 'edit') {
        this.isShowPop = true
        this.formObj = {
          orgId: this.hoverItem.orgId,
          orgName: this.hoverItem.orgName
        }
      } else if (item.type === 'look') {
        window.open(
          domainRoute.rank({
            modelId: '1102',
            modelType: '2',
            orgId: this.hoverItem.orgId
          })
        )
      } else if (item.type === 'freeze') {
        this.isFreeze = true
        this.isShowFreezePop = true
        this.formObj = this.hoverItem
      } else if (item.type === 'cancelFreeze') {
        this.isFreeze = false
        this.isShowFreezePop = true
        this.formObj = this.hoverItem
      } else if (item.type === 'del') {
        this.isShowDeletePop = true
        this.formObj = this.hoverItem

      }
    },
    onCreateMaster(data) {
      this.isShowAssist = true
      this.formObj = { ...this.hoverItem, ...data, userType: 1 }
    },
    onEditManager() {
      this.init()
    },
    onDelOrg() {
      this.$emit('onDelOrg')
    },
    onChangeDate(val) {
      if (val === null) {
        this.startDate = `${this.$moment(getLocalSceneTimeDate(this.sceneType).startDate).format(this.format)}`
        this.endDate = `${this.$moment(getLocalSceneTimeDate(this.sceneType).endDate).format(this.format)}`
      }
      this.init()
    },
    init() {
      this.getOrgManageList({ startDate: this.startDate, endDate: this.endDate })
    }
  }
}
</script>

<style lang="scss" scoped>
.staff-info-box,
.table-box {
  background-color: #fff;
  font-size: 12px;
  line-height: 28px;
}
.label-info {
  color: #5a5a5a;
}
.table-box {
  flex: 1;
}
.custom-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  ::v-deep .el-input__inner {
    background-color: #fafafa;
    border-radius: 20px;
  }
}
.pagination-box {
  text-align: right;
  margin-top: 20px;
}
.footer {
  width: 100%;
  padding: 15px;
  background: #fff;
  border-top: 1px solid #eee;
  text-align: center;
}
.tab-list-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.tab-item {
  width: 70px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  color: #5a5a5a;
  background: #fafafa;
  border-radius: 1px;
  margin-right: 10px;
  cursor: pointer;
  -webkit-touch-callout: none;

  /* 系统默认菜单被禁用 */
  -webkit-user-select: none;

  /* webkit浏览器 */
  -khtml-user-select: none;

  /* 早期浏览器 */
  -moz-user-select: none;

  /* 火狐 */
  -ms-user-select: none;

  /* IE10 */
  user-select: none;
}
.active {
  background-color: #e7ecfb;
  color: #3d61e3;
}
.org-name-box {
  display: inline;
  img {
    vertical-align: middle;
    margin-right: 5px;
  }
}
.mgn-r20 {
  margin-right: 20px;
}
.text-red {
  color: #e8312f;
}
.picker-layout {
  width: 240px;
  margin-left: 20px;
  ::v-deep input {
    background-color: transparent !important;
  }
}
</style>
