<template>
  <div @mouseleave="onMouseleave">
    <el-tree
      v-show="!searchVal"
      ref="ElTree"
      class="custom-tree-style"
      :data="initOrgList"
      node-key="orgId"
      :props="defaultProps"
      :default-expanded-keys="defaultOpen"
      :expand-on-click-node="false"
      :allow-drop="allowDrop"
      icon-class=""
      @node-click="onNodeClick"

      @node-expand="onNodeExpand"
      @node-collapse="onNodeCollapse"
      @node-drop="handleDrop"
    >
      <template slot-scope="{ data }">
        <div class="custom-tree-node" :class="{'check-node':clickNodeKey === data.orgId}">
          <div class="icon-label" @mouseenter="onMouseleave">
            <div class="custom-icon-position" style="padding-top: 4px;">
              <img v-if="openNodeKeyArr.includes(data.orgId) && data.child && data.child.length" src="@/assets/nav/open.png" alt="">
              <img v-else-if="data.child && data.child.length" src="@/assets/nav/close.png" alt="">
              <span v-else style="display: inline-block; width: 20px;" />
              <el-tooltip
                v-if="data.status !== 1"
                effect="light"
                content="该组织已被冻结"
                placement="bottom"
              >
                <img src="@/assets/nav/forbid.png" alt="">
              </el-tooltip>
            </div>
            <div v-if="data.orgName.length <= 10" class="text-ellipsis">{{ data.orgName }}</div>
            <el-tooltip
              v-else
              effect="dark"
              :content="data.orgName"
              placement="bottom"
            >
              <div class="text-ellipsis" style="max-width: 150px;">{{ data.orgName }}</div>
            </el-tooltip>
          </div>
          <div v-show="(clickNodeKey === data.orgId && initHoverList.length) || orgList[0].orgId === data.orgId" @mouseenter="onMouseenter($event,data)">
            <el-button class="" :class="{'dropdown-box': clickNodeKey != data.orgId}" type="text">
              <i class="el-icon-more transform90" />
            </el-button>
          </div>
        </div>
      </template>
    </el-tree>
    <div v-if="searchVal && !orgItemList.length && !representItemList.length " class="no-text-info">
      <i v-if="isLoading" class="el-icon-loading" style="font-size: 36px;" />
      <div v-else>暂无数据</div>
    </div>
    <!-- 搜索的组织、员工列表 -->
    <div v-if="orgItemList.length" class="staff-list-box">
      <div class="search-rest">共{{ orgItemList.length }}条组织结果</div>
      <template v-for="(item) in orgItemList">
        <div
          :key="item.id"
          class="search-rest search-tree is-pointor"
          :class="{'search-click': clickNodeKey === item.id}"
          @click="onNodeClick({orgId:item.id,orgName:item.name})"
        >
          <div class="search-tree-left">
            <el-tooltip
              v-if="item.status !== 1"
              effect="light"
              content="该组织已被冻结"
              placement="bottom"
            >
              <img src="@/assets/nav/forbid.png" alt="" style="margin-top: -2px;">
            </el-tooltip>

            <el-tooltip effect="dark" :content="item.path" placement="bottom">
              <div class="text-ellipsis" style="padding-left: 5px;">{{ item.path }}</div>
            </el-tooltip>
          </div>
          <div v-show="clickNodeKey === item.id" @mouseenter="onMouseenter($event,{orgId:item.id,orgName:item.name},0)">
            <el-button class="" :class="{'dropdown-box': clickNodeKey === item.id}" type="text">
              <i class="el-icon-more transform90" />
            </el-button>
          </div>
        </div>
      </template>
    </div>

    <div v-if="representItemList.length" class="staff-list-box">
      <div class="search-rest">共{{ representItemList.length }}条员工结果</div>
      <template v-for="(item) in representItemList">
        <div :key="item.id +item.orgId" class="search-rest is-pointor" @click="handleSearchStaffDetail(item)">
          <div class="staff-info">
            <el-tooltip effect="dark" :content="item.name + (item.status !== 1 ? '已冻结':'')" placement="bottom">
              <div class="text-ellipsis staff-name">{{ item.name + (item.status !== 1 ? ' (已冻结)':'') }}</div>
            </el-tooltip>
            <span>{{ item.mobile }}</span>
          </div>
          <div v-if="item.orgName.length <= 10" class="text-ellipsis">{{ item.orgName }}</div>
          <el-tooltip
            v-else
            effect="dark"
            :content="item.orgName"
            placement="bottom"
          >
            <div class="text-ellipsis" style="max-width: 200px;">{{ item.orgName }}</div>
          </el-tooltip>
        </div>
      </template>
    </div>

    <!-- 操作列表 -->
    <div
      v-show="isShowHover"
      class="dropdown-custom-item"
      :style="{top:targetTop + 'px',left:targetLeft+'px'}"
      @mouseleave="onMouseleave"
    >
      <template v-for="item in hoverList">
        <div
          :key="item.type"
          class="custom-item"
          :class="{'text-red': ['del','freeze','cancelFreeze'].includes(item.type)}"
          @click="onClickCommand(item)"
        >
          {{ item.name }}
        </div>
      </template>
    </div>

    <!-- 弹窗 -->
    <AddOrginazation
      v-if="isShowPop"
      :form-obj="formObj"
      :type="operateType"
      :is-show-pop="isShowPop"
      @onEditConfirm="onEditConfirm"
      @onClose="(val) => {isShowPop = val}"
    />
    <FreezeOrgDialog
      :form-obj="formObj"
      :is-show-pop="isShowFreezePop"
      :is-freeze="isFreeze"
      @onClose="(val) => {isShowFreezePop = val}"
    />
    <DeleteDialog
      :form-obj="formObj"
      :is-show-pop="isShowDeletePop"
      @onDelOrg="onDelOrg"
      @onClose="(val) => {isShowDeletePop = val}"
    />
    <RemoveOrg
      v-if="isShowRemoveOrgPop"
      :form-obj="removeRestObj"
      :is-show-pop="isShowRemoveOrgPop"
      @onRemoveOrgClick="onRemoveOrgClick"
      @onClose="onCloseRemoveOrg"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AddOrginazation from './AddOrginazation'
import FreezeOrgDialog from './FreezeOrgDialog'
import DeleteDialog from './DeleteDialog'
import RemoveOrg from './RemoveOrg'

import domainRoute from '@/utils/domain-route'

export default {
  name: 'OrginazationTree',
  components: {
    AddOrginazation,
    FreezeOrgDialog,
    DeleteDialog,
    RemoveOrg
  },
  props: {
    searchVal: {
      type: String,
      default: ''
    },
    orgItemList: {
      type: Array,
      default: () => {
        return []
      }
    },
    representItemList: {
      type: Array,
      default: () => {
        return []
      }
    },
    isLoading: {
      type: Boolean,
      default: true
    },
    reqRest: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      treeList: null,
      defaultProps: {
        children: 'child',
        label: 'orgName',
        value: 'orgId',
        disabled: 'isSelect',
        checkStrictly: true
      },

      clickNodeKey: null,
      editItem: {},
      hoverItem: {},
      hoverParentItem: {},
      hoverList: [],
      initHoverList: [],
      initHoverItem: {},
      operateType: 'add',
      listOne: [
        {
          type: 'add',
          name: '添加子部门',
          auth: 'crm.orgMgmt.orgRel.createOrg'
        },
        {
          type: 'look',
          name: '查看数据'
        }
      ].filter(item => item.auth ? this.$auth(item.auth) : true),
      isShowHover: false,
      targetTop: 165,
      targetLeft: 235,
      openNodeKeyArr: [],
      initOpenNodeKeyArr: [],

      isShowPop: false,
      isShowFreezePop: false,
      isFreeze: true,
      isShowDeletePop: false,
      formObj: {},

      isShowRemoveOrgPop: false,
      removeRestObj: {}

    }
  },
  computed: {
    ...mapGetters(['orgList']),
    initOrgList: {
      get() {
        return this.treeList
      },
      set(list) {
        const id = list[0]?.orgId
        if (id) {
          this.remove(id)
          this.openNodeKeyArr.push(id)
          this.initOpenNodeKeyArr.push(id)
        }
        this.treeList = list
      }
    },
    defaultOpen: {
      get() {
        if (this.clickNodeKey) {
          return this.openNodeKeyArr
        }
        const obj = this.orgList[0] || {}
        this.onNodeClick(obj)
        return this.openNodeKeyArr
      },
      set(arr) {
        this.openNodeKeyArr = arr
      }
    },
    mustOpen: {
      get() {
        const obj = this.orgList[0] || {}
        return obj.orgId || ''
      }
    }
  },
  watch: {
    orgList: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal,'watch')
        this.initOrgList = JSON.parse(JSON.stringify(newVal || oldVal))
        if (this.operateType === 'del') {
          this.onNodeClick({})
        } else if (this.clickNodeKey) {
          this.onNodeClick({...this.hoverItem, ...this.editItem})
        }
      }
    }
  },
  methods: {
    ...mapActions(['getOrgList', 'getOrgOperate', 'onMoveDepartment']),
    // ---------------- 工具函数 ------------------------------
    indexOf(val, list = this.openNodeKeyArr) {
      if (list.length) {
        for (let i = 0; i < list.length; i++) {
          if (list[i] === val) {
            return i
          }
        }
      } else {
        return -1
      }
    },
    remove(val) {
      const index = this.indexOf(val, this.openNodeKeyArr)
      if (index > -1) {
        this.openNodeKeyArr.splice(index, 1)
      }
    },
    removeInit(val) {
      const index = this.indexOf(val, this.initOpenNodeKeyArr)
      if (index > -1) {
        this.initOpenNodeKeyArr.splice(index, 1)
      }
    },
    unique(arr) {
      return Array.from(new Set(arr))
    },
    // ----------------------- end --------------------------
    onNodeClick(data) {     // 节点被点击
      if (this.reqRest) {
        // this.$message.warning("操作太快，请稍后再试")
        return
      }
      this.clickNodeKey = data.orgId
      this.hoverItem = data
      if (this.clickNodeKey) {
        this.onGetOperate(data.orgId)
        this.$emit('onClickNode', data)
      }
    },
    onEditConfirm(data) {
      this.editItem = {orgId: data.orgId, orgName: data.orgName}
    },
    handleSearchStaffDetail(data) {
      this.staffSearchClick = data.representId
      this.$router.push({path: `/orginazation/staff/${data.id}?mobile=${data.mobile}&orgId=${data.orgId}`})
    },
    onDelOrg() {
      this.$emit('onDelOrg')
    },
    onGetOperate(id) {
      this.hoverList = []
      this.initHoverList = []
      if (id === this.orgList[0].orgId) {
        this.hoverList = this.listOne
        return
      }
      this.getOrgOperate({orgId: id}).then(res => {
        const {data = {}} = res
        const list = []
        if (data.canAdd && this.$auth('crm.orgMgmt.orgRel.createOrg')) {
          list.push(
            {
              type: 'add',
              name: '添加子部门'
            }
          )
        }
        if (this.$auth('crm.orgMgmt.orgRel.viewOrgData')) {
          list.push(
            {
              type: 'look',
              name: '查看数据'
            }
          )
        }
        if (data.canEdit && this.$auth('crm.orgMgmt.orgRel.editOrg')) {
          list.push(
            {
              type: 'edit',
              name: '编辑部门'
            }
          )
        }
        if (data.displayDelBtn && this.$auth('crm.orgMgmt.orgRel.deleteOrg')) {
          list.push(
            {
              type: 'del',
              name: '删除'
            }
          )
        }
        if (data.displayFrozenBtn && !data.isFrozen && this.$auth('crm.orgMgmt.orgRel.freezeOrg')) {
          list.push(
            {
              type: 'freeze',
              name: '冻结'
            }
          )
        } else if (data.displayFrozenBtn && data.isFrozen && this.$auth('crm.orgMgmt.orgRel.unfreezeOrg')) {
          list.push(
            {
              type: 'cancelFreeze',
              name: '取消冻结'
            }
          )
        }
        this.initHoverList = list
      })
    },
    onNodeExpand(data) {   // 节点展开
      this.initOpenNodeKeyArr.push(data.orgId)
      this.initOpenNodeKeyArr = this.unique(this.initOpenNodeKeyArr)
      const list = JSON.parse(JSON.stringify(this.initOpenNodeKeyArr))
      const removeNode = item => {
        const index = this.indexOf(item.orgId, list)
        if (index > -1) {
          list.splice(index, 1)
        }
      }
      const onNodeDelChild = data => {   // 节点打开,移出不存在的orgId的子节点
        removeNode(data)
        if (data?.child?.length) {
          for (const item of data.child) {
            onNodeDelChild(item)
          }
        }
      }
      const childFun = data => {
        if (data.child?.length) {
          data?.child?.map(item => {
            if (!list.includes(item.orgId)) {
              onNodeDelChild(item)
            } else {
              childFun(item)
            }
          })
        }
      }
      childFun(this.initOrgList[0])

      this.$set(this, 'openNodeKeyArr', list)
    },
    onNodeCollapse(data) {   // 节点收起
      this.removeInit(data.orgId)
      this.onNodeCollapseChild(data)
    },
    onNodeCollapseChild(data) {   // 节点收起,移出子节点
      this.remove(data.orgId)
      if (data?.child?.length) {
        for (const item of data.child) {
          this.onNodeCollapseChild(item)
        }
      }
    },
    handleDrop(draggingNode, dropNode, dropType) {      // 拖拽成功完成时触发的事件
      // console.log("拖拽成功",draggingNode, dropNode, dropType, ev)
      this.isShowRemoveOrgPop = true
      this.removeRestObj = {
        draggingNode,
        dropNode,
        dropType
      }

    },
    onRemoveOrgClick() {
      const {draggingNode, dropNode, dropType} = this.removeRestObj
      if (dropType === 'inner') {
        this.onMoveOrg({orgId: draggingNode.data.orgId, toOrgId: dropNode.data.orgId})
      } else {
        this.onMoveOrg({orgId: draggingNode.data.orgId, toOrgId: dropNode.parent.data.orgId})
      }
    },
    onCloseRemoveOrg(options) {
      this.isShowRemoveOrgPop = false
      options.isGet && this.getOrgList()
    },
    allowDrop(draggingNode, dropNode, type) {     //  拖拽时判定目标节点能否被放置。type 参数有三种情况：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
    // console.log(draggingNode, dropNode, type,'====================')
      if (dropNode.level === 1) {
        return type === 'inner'
      } else if (dropNode.data.status !== 1) {
        return false
      } else {
        return true
      }
    },
    allowDrag(draggingNode) {     // 判断节点能否被拖拽

      return draggingNode.data.status !== -1
    },
    onMoveOrg(data) {
      this.onMoveDepartment(data).then(() => {
        this.getOrgList()
      }, rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
        this.getOrgList()
      })
    },
    onMouseenter(event, item, baseTop = 40) {
      if (item.orgId === this.orgList[0].orgId) {
        this.hoverList = this.listOne
      } else {
        this.hoverList = this.initHoverList
      }
      this.initHoverItem = item
      // 弹窗定位
      const left = event.offsetLeft
      const height = event.target.offsetHeight * 4
      this.targetLeft = left
      this.targetTop = event.target.offsetTop + height
      if (this.targetTop + height + baseTop > document.body.scrollHeight) {
        this.targetTop -= height
      }
      this.isShowHover = true
    },
    onMouseleave() {
      this.isShowHover = false
    },

    onClickCommand(item) {
      this.operateType = item.type
      if (item.type === 'add') {
        this.isShowPop = true
        this.formObj = {
          parentOrgName: this.initHoverItem.orgName,
          parentOrgId: this.initHoverItem.orgId
        }
      } else if (item.type === 'edit') {
        this.isShowPop = true
        this.formObj = {
          orgId: this.hoverItem.orgId,
          orgName: this.hoverItem.orgName
        }
      } else if (item.type === 'look') {
        window.open(
          domainRoute.rank({
            modelId: '1102',
            modelType: '2',
            orgId: this.initHoverItem.orgId
          })
        )
      } else if (item.type === 'freeze') {
        this.isFreeze = true
        this.isShowFreezePop = true
        this.formObj = this.hoverItem
      } else if (item.type === 'cancelFreeze') {
        this.isFreeze = false
        this.isShowFreezePop = true
        this.formObj = this.hoverItem
      } else if (item.type === 'del') {
        this.isShowDeletePop = true
        this.formObj = this.hoverItem

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-tree-style {
  padding: 15px;
  ::v-deep .el-tree-node__content {
    height: 36px;
    margin-left: -27px;
  }
  ::v-deep .el-tree-node__expand-icon {
    position: relative;
    left: 26px;
    z-index: 50;
    opacity: 0;
  }
  ::v-deep .is-current > .el-tree-node__content {
    background: #fff !important;
  }
  & > ::v-deep .el-tree-node {
    padding: 20px 0;
    border-bottom: 1px solid #eee;
    overflow: hidden;
  }
}
.icon-label {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
  img {
    margin-right: 8px;
  }
}
.custom-tree-node {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  overflow: hidden;
  height: 28px;
}
.check-node {
  background: #e7ecfb !important;
  color: #3d61e3;
}
.dropdown-box {
  color: #979797;
}
.dropdown-custom-item {
  position: absolute;
  z-index: 30;
  width: 113px;
  background: #fbfbfb;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 1px;
  border: 1px solid #e1e1e1;
}
.custom-item {
  height: 32px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
}
.custom-item:hover {
  background: rgba(61, 97, 227, 0.12);
  color: #3d61e3;
}
.staff-list-box {
  padding: 15px;
  font-size: 14px;
  text-align: left;
  color: #787878;
}
.search-rest {
  padding: 10px 5px;
  line-height: 20px;
  border-bottom: 1px solid #eee;
}
.search-tree {
  padding: 0 5px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-tree-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 200px;
}
.staff-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 200px;
  .staff-name {
    font-size: 14px;
    color: #0f0f0f;
  }
}
.search-click {
  color: #3d61e3;
  background: #e7ecfb;
}
.mgn-r10 {
  margin-right: 10px;
}
.mgn-r20 {
  margin-right: 20px;
}
.transform90 {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg); 	/* IE 9 */
  -moz-transform: rotate(90deg); 	/* Firefox */
  -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
  -o-transform: rotate(90deg);
}
.no-text-info {
  color: #909399;
  padding-top: 40px;
}
.text-red {
  color: #e8312f;
}
</style>
